
import { Add, AttachMoney, CheckBox, CheckBoxOutlineBlank, ChevronLeft, ChevronRight, MonetizationOn, MoneyOff, PriceCheck, Refresh, Remove, ShoppingCart } from "@mui/icons-material"
import { Alert, Avatar, Box, Button, Chip, Divider, Fab, FormControlLabel, Grid, IconButton, List, ListItem, ListItemAvatar, ListItemText, ListSubheader, Rating, Stack, TextField, Toolbar, Typography } from "@mui/material"
import { useEffect, useRef, useState } from "react"
import { hex_md5 } from "react-native-md5"
import apis from "../../../api"
import { LOGO_ICON_COLOR, LOGO_TEXT_COLOR } from "../../../app/config"
import { Drawer } from "../../../Component/MuiEx"
import { apiResult, formatDate, getTeacherSession } from "../../../Utils/Common"
import BuyGiftDialog from "./BuyGiftDialog"

const MembersSideMenu = (props) => {
    const { scheduleid, begintime, sdate, duration, onMemberClick } = props
    const [members, setMembers] = useState()
    const [inLesson, setinLesson] = useState(false)
    const [selected, setSelected] = useState(-1)
    const [error, setError] = useState()
    const [open, setOpen] = useState(false);
    const [giftDialog, setGiftDialog] = useState(false)
    const [count, setCount] = useState(begintime > 0 ? (new Date().getHours() * 60 + new Date().getMinutes() - Number(begintime)) : 0)
    const countRef = useRef(count)
    const timeout = useRef()
    const [checkin, setCheckIn] = useState()
    const [comments, setComments] = useState({ changed: false })
    const initPage = useRef(false)
    getTeacherSession(apis)
    const autoIncrement = () => {
        countRef.current = new Date().getHours() * 60 + new Date().getMinutes() - Number(begintime)
        //console.log([sdate,formatDate(new Date())])
        if (!inLesson && sdate == formatDate(new Date()) && countRef.current > -30 && countRef.current < Number(duration)) setinLesson(true)
        setCount(countRef.current)
        if (timeout.current) window.clearTimeout(timeout.current)
        timeout.current = window.setTimeout(autoIncrement, 1000)
    }
    const loadDatas = ()=>{
        apis.teacherScheduleInfo(scheduleid).then(ret => {
            apiResult(ret, data => {
                if(data.memberrecords && data.memberrecords.length>0 && data.members && data.members.length>0) {
                    for(let r of data.memberrecords) {
                        for(let m of data.members) {
                            if(m.id == r.member_id) {
                                m.record = r
                            }
                        }
                    }
                }
                setMembers(data.members)
                setCheckIn(data.checkin)
            }, setError)
        })
    }
    useEffect(() => {
        if (initPage.current) {
            return
        }
        initPage.current = true
        timeout.current = window.setTimeout(autoIncrement, 1000)
        loadDatas()

    }, [])
    const handleMemberClick = (idx) => {
        if (idx != selected) {
            if (comments?.changed) {
                if (!window.confirm("You have not submitted the comments, continue without submitting?")) {
                    return
                }
            }
            setSelected(idx)
            if (!members[idx].record) {
                apis.teacherLessonRecord(scheduleid, members[idx].id).then(ret => {
                    apiResult(ret, data => {
                        if (data.hasData) {
                            let mm = [...members]
                            mm[idx].record = data.record
                            setMembers(mm)
                            setComments(data.record)
                        } else {
                            setComments({ changed: false, member_id: members[idx].id, schedule_id: scheduleid })
                        }
                    }, setError)
                })
            } else {
                setComments(members[idx].record)
            }
            onMemberClick && onMemberClick(members[idx])
        }
    }
    //const navigate = useNavigate()
    const toggleDrawer = () => {
        setOpen(!open);
    };
    const dealPoints = (points) => {
        let time = new Date().getTime()
        let action = 'earn'
        let token = hex_md5(scheduleid + String(points) + String(time) + action + members[selected].id)
        members && selected >= 0 && apis.teacherPoints({ schedule_id: scheduleid, member_id: members[selected].id, token, points, time, action }).then(ret => {
            apiResult(ret, data => {
                let mm = [...members]
                mm[selected].points = data?.totalPoints
                if (mm[selected].record) {
                    mm[selected].record.points = data?.lessonPoints
                }
                setMembers(mm)
            }, setError)
        })
    }
    const handleAddPoints = () => {
        dealPoints(1)
    }
    const handleMinusPoints = () => {
        dealPoints(-1)
    }
    const handleCommentChange = (name, value) => {
        let cc = { ...comments }
        cc.changed = true
        cc[name] = value
        setComments(cc)
    }
    const handleSubmit = () => {
        if (comments.changed) {
            if (comments.comment === null) delete comments.comment
            apis.teacherLessonRecordPost(comments).then(ret => {
                apiResult(ret, data => {
                    setComments({ ...comments, changed: false })
                    let mm = [...members]
                    mm[selected].record = data
                    setMembers(mm)
                }, setError)
            })
        }
    }
    const handleBuyGift = () => {
        setGiftDialog(true)
    }
   
    const handleBuy = (amount, note, callback) => {
        if (amount != 0) {
            let time = new Date().getTime()
            let member_id = members[selected].id
            let action = 'buy gift'
            let token = hex_md5(String(amount) + String(time) + action + member_id)
            apis.teacherUsePoints({ member_id, token, action, time, amount, note }).then(ret => {
                apiResult(ret, data => {
                    let mm = [...members]
                    mm[selected].used_points = data.usedPoints
                    setMembers(mm)
                    callback()
                }, callback)
            })
        }
    }
    const handleMemberCheckin = (idx,id)=>{
        setSelected(idx)
        checkin && apis.teacherLessonRecordPost({schedule_id:scheduleid,member_id:id,checkin:members[idx].record&&members[idx].record.checkin == 1?0:1}).then(ret=>{
            apiResult(ret,data=>{
                let mm = [...members]
                mm[idx].record = data
                setMembers(mm)
            },setError)
        })

    }
    const handleCheckin = () => {
        !checkin && apis.teacherCheckin(scheduleid).then(ret => {    //??????
            apiResult(ret, data => {
                setCheckIn(true)
            }, setError)
        })
    }
    return <Drawer variant="permanent" open={open} sx={{ borderLeft: '1px solid #0002', width: open ? '400px' : null }}>
        <Toolbar 
            sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'flex-end',
                px: [1],
                background: 'white',
                color: LOGO_ICON_COLOR,
            }}>
            <IconButton onClick={toggleDrawer} sx={{ background: 'white' }}>
                {!open && <ChevronLeft sx={{ display: open || !inLesson ? 'block' : 'none' }} />}
                {open && <ChevronRight sx={{ display: open || !inLesson ? 'block' : 'none' }} />}
                {inLesson && !open && <Avatar sx={{ bgcolor: count < duration ? 'green' : 'red' }}>{count}</Avatar>}
            </IconButton>
            <Typography sx={{ flex: '1 1 10%' }} variant="h6" component="div" > </Typography>
            {open && inLesson && checkin && <Typography variant="h6" sx={{ color: count < duration && inLesson ? 'green' : 'gray' }}>{
                inLesson ? (count < duration ? (count + " minutes") : ("Over time " + (count - Number(duration)) + " minutes")) : "Not in lesson"
            }</Typography>}
            {open && !checkin && <Button variant="contained" onClick={() => { handleCheckin() }}>Check in</Button>}
            {open && <IconButton title="Reload data" onClick={()=>loadDatas()}><Refresh/></IconButton>}
        </Toolbar>
        <Divider />
        {error && <Alert severity="error" onClose={() => setError()}>{error}</Alert>}
        <List component="nav" sx={{ p: 0 }} subheader={open && <ListSubheader>Students</ListSubheader>}>
            {members && members.map((item, idx) => {
                return <ListItem selected={idx === selected} key={idx} onClick={() => { handleMemberClick && handleMemberClick(idx) }} sx={{ pt: 1, pb: 1, cursor: 'pointer' }}
                    secondaryAction={
                        open && <IconButton edge="end" title="Check in" onClick={()=>{handleMemberCheckin(idx,item.id)}}>
                            {item.record && item.record.checkin == 1?<CheckBox />:<CheckBoxOutlineBlank />}
                        </IconButton>}>
                    <ListItemAvatar sx={{ display: open ? 'none' : 'block' }} title={item.name}>
                        <Avatar sx={{ bgcolor: idx === selected ? LOGO_ICON_COLOR : LOGO_TEXT_COLOR }}>{item?.name?.substring(0, 2)}</Avatar>
                    </ListItemAvatar>
                    <ListItemText sx={{ color: LOGO_TEXT_COLOR }}>{item.name}</ListItemText>
                </ListItem>
            }
            )}
        </List>
        {open && selected >= 0 && <Box>
            <Divider />
            <List component="nav" sx={{ p: 0 }} subheader={<ListSubheader>{members[selected].name}</ListSubheader>}>
                <ListItem>
                    <Box sx={{ '& > :not(style)': { m: 0.5 }, ml: 0 }}>
                        <Fab variant="extended" size="small">
                            <AttachMoney sx={{ mr: 1 }} />{members[selected].record?.points}
                        </Fab>
                        <Fab variant="extended" size="small">
                            <MonetizationOn sx={{ mr: 1 }} />{members[selected].points}
                        </Fab>
                        <Fab variant="extended" size="small">
                            <MoneyOff sx={{ mr: 1 }} />{members[selected].used_points}
                        </Fab>
                        {checkin && members[selected].record && members[selected].record.checkin == 1 && <Fab color="primary" size='small' aria-label="add" onClick={handleAddPoints}>
                            <Add />
                        </Fab>}
                        {checkin && members[selected].record && members[selected].record.checkin == 1 && members[selected].record && members[selected].record.points>0 && <Fab size="small" color="secondary" aria-label="minus" onClick={handleMinusPoints}>
                            <Remove />
                        </Fab>}
                        {checkin && members[selected].record && members[selected].record.checkin == 1 && members[selected].points>0 && <Fab color="primary" size='small' aria-label="add" onClick={handleBuyGift}>
                            <ShoppingCart />
                        </Fab>}
                    </Box>
                </ListItem>
                <ListItem>
                    <TextField placeholder="Typing speed" disabled={!checkin || members[selected].record && members[selected].record.checkin != 1} value={comments.score6 ? comments.score6 : 0} onChange={(e) => handleCommentChange('score6', e.target.value)} fullWidth label="Typing speed" />
                </ListItem>
                <ListItem>
                    <Grid container spacing={2}>
                        <Grid item xs={6}>
                            <FormControlLabel  labelPlacement="start" sx={{ ml: 0 }} value={comments.score1 ? comments.score1 : 0} control={<Rating  disabled={!checkin || members[selected].record && members[selected].record.checkin != 1} onChange={(e) => handleCommentChange('score1', e.target.value)} sx={{ mt: -0.6, ml: 1 }} precision={1} />} label='C' title="Comprehension" />
                        </Grid>
                        <Grid item xs={6}>
                            <FormControlLabel labelPlacement="start" sx={{ ml: 0 }} value={comments.score2 ? comments.score2 : 0} control={<Rating disabled={!checkin || members[selected].record && members[selected].record.checkin != 1} onChange={(e) => handleCommentChange('score2', e.target.value)} sx={{ mt: -0.6, ml: 1 }} precision={1} />} label='F' title="Focus" />
                        </Grid>
                        <Grid item xs={6}>
                            <FormControlLabel labelPlacement="start" sx={{ ml: 0 }} value={comments.score3 ? comments.score3 : 0} control={<Rating disabled={!checkin || members[selected].record && members[selected].record.checkin != 1} onChange={(e) => handleCommentChange('score3', e.target.value)} sx={{ mt: -0.6, ml: 1 }} precision={1} />} label='P' title="Participation" />
                        </Grid>
                        <Grid item xs={6}>
                            <FormControlLabel labelPlacement="start" sx={{ ml: 0 }} value={comments.score4 ? comments.score4 : 0} control={<Rating disabled={!checkin || members[selected].record && members[selected].record.checkin != 1} onChange={(e) => handleCommentChange('score4', e.target.value)} sx={{ mt: -0.6, ml: 1 }} precision={1} />} label='D' title="Discipline" />
                        </Grid>
                        <Grid item xs={6}>
                            <FormControlLabel labelPlacement="start" sx={{ ml: 0 }} value={comments.score5 ? comments.score5 : 0} control={<Rating disabled={!checkin || members[selected].record && members[selected].record.checkin != 1} onChange={(e) => handleCommentChange('score5', e.target.value)} sx={{ mt: -0.6, ml: 1 }} precision={1} />} label='H' title="Homework" />
                        </Grid>
                    </Grid>
                </ListItem>
                <ListItem>
                    <TextField fullWidth multiline rows={6} disabled={!checkin || members[selected].record && members[selected].record.checkin != 1} placeholder="comments" value={comments.comment ? comments.comment : ''} onChange={(e) => handleCommentChange('comment', e.target.value)} />
                </ListItem>
                <ListItem>
                    <Button sx={{ mt: 1 }} variant="contained" disabled={!checkin || members[selected].record && members[selected].record.checkin != 1 || !comments.changed} size="small" onClick={handleSubmit}>Save</Button>
                </ListItem>
            </List>
        </Box>}
        <BuyGiftDialog open={giftDialog} onSubmit={handleBuy} />
    </Drawer>
}

export default MembersSideMenu