// //import Editor from "@monaco-editor/react";
// import Editor from 'react-monaco-editor';
// import { Box } from "@mui/material"
// import { useState } from "react";
// import { useEffect } from "react";
// function CodeEditor(props) {    
//     const handleChange = (v, e) => {
//         props.onChange && props.onChange(v)
//     }
//     const [editor, setEditor] = useState()
//     useEffect(() => {
//         if(editor) {
//             setEditor()
//         }
        
//         window.setTimeout(()=>{            
            
//             props.value ? setEditor(
//                 <Editor
//                     InputProps = {{style:{fontSize:props.size?props.size:null}}}
//                     height={props.height}
//                     defaultLanguage="python"
//                     value={props.value}
//                     options={{
//                         minimap: { enabled: false },
//                         lineNumbers: 'on',
//                         scrollbar: {
//                             verticalScrollbarSize: 2,
//                             horizontalScrollbarSize: 2,
//                         },
//                         overviewRulerBorder: false,
//                         tabSize: 4,
//                         autoIndent: true,
//                     }}
//                     theme={'vs-dark'}
//                     onChange={handleChange}
//                 />
//             ):setEditor(
//                 <Editor
//                     InputProps = {{style:{fontSize:props.size?props.size:null}}}
//                     height={props.height}
//                     defaultLanguage="python"                    
//                     options={{
//                         minimap: { enabled: false },
//                         lineNumbers: 'on',
//                         scrollbar: {
//                             verticalScrollbarSize: 2,
//                             horizontalScrollbarSize: 2,
//                         },
//                         overviewRulerBorder: false,
//                         tabSize: 4,
//                         autoIndent: true,
//                     }}
//                     theme={'vs-dark'}
//                     onChange={handleChange}
//                 />
//             )
//         },1)        
//     }, [props.index,props.subindex,props.value])
//     return <Box sx={{ ...(props.sx?props.sx:{}),pt: 2, pb: 2, bgcolor: 'black' }}>{editor}
//     </Box>
// }

// export default CodeEditor

// import Editor from "@monaco-editor/react";
import CodeMirror from '@uiw/react-codemirror';
import { python } from '@codemirror/lang-python';
import { Box } from "@mui/material"
import { useCallback} from "react";
import { EditorView } from "@codemirror/view";


function CodeEditor(props) {
    const {onBlur,onFocus} = props
    const onChange = useCallback((value, viewUpdate) => {
        props.onChange && props.onChange(value)
    }, []);
    const Theme = EditorView.theme({        
        "&": {
            fontSize: props.size,
            background:'#fff',
          }}
      );
    return <Box sx={{ pt: 2, pb: 2,background:props.bgcolor}}>
        <CodeMirror
            value={props.value ? String(props.value):""}
            height={props.height}
            theme={Theme}
            autoFocus={true}
            extensions={[python()]}
            onChange={onChange}
            onFocus={onFocus}
            onBlur = {onBlur}
        />
    </Box>
}

export default CodeEditor