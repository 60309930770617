import { hex_md5 } from 'react-native-md5'
const apiResult = (ret, successCallback, failCallback, fieldCheckCallback) => {
    if (ret.status === 200 && ret.data.success) {
        successCallback(ret.data.data)
    } else {
        if (ret.data.data.errcode === 990000) {
            let es = {}
            ret.data.data.errors.map((item) => {
                es = { ...es, [item.param]: item.msg }
                return true
            })
            fieldCheckCallback ? fieldCheckCallback(es) : failCallback('Parameters error')
        } else if ([900002, 900003, 900004].indexOf(ret.data.data.errcode) >= 0) {
            setUserSession()
            window.location.href = "/user/signin"
        } else {
            failCallback(ret.data.data.error)
        }
    }
}
const formToJson = (formData) => {
    const data = {};
    formData.forEach((value, key) => {
        data[key] = key === 'passwd' ? hex_md5(value) : value
    });
    return data;
}

const sessionSet = (key, value) => {    
    if (!value) {        
        sessionStorage.removeItem(key)
        return
    }
    const data = { data: value }
    sessionStorage.setItem(key, JSON.stringify(data))
}
const sessionGet = (key) => {
    let value = sessionStorage.getItem(key)
    value = value ? JSON.parse(value) : value
    return value ? value.data : value
}
const cacheSet = (key, value) => {
    if (!value) {
        localStorage.removeItem(key)
        return
    }
    localStorage.setItem(key, JSON.stringify({ data: value }))
}

const cacheGet = (key) => {
    let value = localStorage.getItem(key)
    value = value ? JSON.parse(value) : value
    return value ? value.data : value
}
const getUserSession = (apis) => {
    let session = sessionGet('member_session')
    session = session ? session : cacheGet('member_session')
    //console.log([session.expired_time,Date.now()])
    if (session && session.expired_time < Date.now()) {
        sessionSet('member_session', undefined)
        cacheSet('member_session', undefined)
        return undefined
    }
    if (session && apis) {
        const time = Date.now()
        apis.setHeader('request-userid', session.userid)
        apis.setHeader('request-appid', 0)
        apis.setHeader('request-token', hex_md5(session.token + time))
        apis.setHeader('request-time', time)
    }
    return session
}
const setUserSession = (data, cache = false) => {
    sessionSet('member_session', data)
    const session = cacheGet('member_session')
    if (session && session.expired_time > Date.now()) {
        cacheSet('member_session', data)
    } else if (cache) {
        cacheSet('member_session', data)
    }

}
const getTeacherSession = (apis) => {
    let session = sessionGet('teacher_session')
    session = session ? session : cacheGet('teacher_session')
    //console.log([session.expired_time,Date.now()])
    if (session && session.expired_time < Date.now()) {
        sessionSet('teacher_session', undefined)
        cacheSet('teacher_session', undefined)
        return undefined
    }
    if (session && apis) {
        const time = Date.now()
        apis.setHeader('request-teacherid', session.userid)
        apis.setHeader('request-appid', 0)
        apis.setHeader('request-token', hex_md5(session.token + time))
        apis.setHeader('request-time', time)
    }
    return session
}
const setTeacherSession = (data, cache = false) => {
    sessionSet('teacher_session', data)
    const session = cacheGet('teacher_session')
    if (session && session.expired_time > Date.now()) {
        cacheSet('teacher_session', data)
    } else if (cache) {
        cacheSet('teacher_session', data)
    }

}
const getMemberSession = (apis) => {
    let session = sessionGet('member_session')
    session = session ? session : cacheGet('member_session')
    //console.log([session.expired_time,Date.now()])
    if (session && session.expired_time < Date.now()) {
        sessionSet('member_session', undefined)
        cacheSet('member_session', undefined)
        return undefined
    }
    if (session && apis) {
        const time = Date.now()
        apis.setHeader('request-memberid', session.userid)
        apis.setHeader('request-appid', 0)
        apis.setHeader('request-token', hex_md5(session.token + time))
        apis.setHeader('request-time', time)
    }
    return session
}
const setMemberSession = (data, cache = false) => {
    sessionSet('member_session', data)
    const session = cacheGet('member_session')
    if (session && session.expired_time > Date.now()) {
        cacheSet('member_session', data)
    } else if (cache) {
        cacheSet('member_session', data)
    }

}
const getVarsFromString = (str, symbol) => {
    const arrs = str.split(symbol)
    const rets = []
    for (let i = 0; i < arrs.length; i++) {
        i % 2 === 1 && rets.push(arrs[i])
    }
    return rets
}

const formatDate = (date) => {
    var d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

    if (month.length < 2)
        month = '0' + month;
    if (day.length < 2)
        day = '0' + day;

    return [year, month, day].join('-');
}


function fallbackCopyTextToClipboard(text) {
    var textArea = document.createElement("textarea");
    textArea.value = text;

    // Avoid scrolling to bottom
    textArea.style.top = "0";
    textArea.style.left = "0";
    textArea.style.position = "fixed";

    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();

    try {
        var successful = document.execCommand('copy');
        var msg = successful ? 'successful' : 'unsuccessful';
        console.log('Fallback: Copying text command was ' + msg);
    } catch (err) {
        console.error('Fallback: Oops, unable to copy', err);
    }

    document.body.removeChild(textArea);
}
function copyTextToClipboard(text) {
    if (!navigator.clipboard) {
        fallbackCopyTextToClipboard(text);
        return;
    }
    navigator.clipboard.writeText(text).then(function () {
        console.log('Async: Copying to clipboard was successful!');
    }, function (err) {
        console.error('Async: Could not copy text: ', err);
    });
}

const getDatesInRange = (startDate, endDate) => {
    const date = new Date(startDate);
    const date1 = new Date(endDate)
    const dates = [];
    //console.log([startDate,endDate,date,date1])
    while (date < date1) {
        let m = date.getMonth()+1
        let d = date.getDate()
        //dates.push(date.getFullYear()+'-'+(m<10?('0'+m):m)+ '-' + (d<10?('0'+d):d))
        dates.push(new Date(date).toISOString().substring(0,10));
        date.setDate(date.getDate() + 1);        
    }
    return dates;
  }
// const getLocaleDate = (date)=>{
//     return date.getFullYear()+"-"+((date.getMonth()+1)<10?('0'+(date.getMonth()+1)):(date.getMonth()+1))+'-'+(date.getDate()<10?('0'+date.getDate()):date.getDate())
// }
const checkInLesson = (sdate,begintime,duration,etime) => {
    let date = new Date()
    let today = getLocaleDate(date)
    let tm = date.getHours()*60+date.getMinutes()    
    if(today === sdate && tm >= (begintime - (etime?etime:10)) && tm<= begintime+duration +(etime?etime:10)) {        
        return 0
    }else if(today < sdate || (today == sdate && tm<begintime)) {
        return -1
    }else {
        return 1
    }
}

const whatType = (object) => {
    var stringConstructor = "test".constructor;
    var arrayConstructor = [].constructor;
    var objectConstructor = ({}).constructor;        
    if (object === null) {
        return undefined;
    }else if (object === undefined) {
        return undefined;
    }else if (object.constructor === stringConstructor) {
        return "string";
    }else if (object.constructor === arrayConstructor) {
        return "array";
    }else if (object.constructor === objectConstructor) {
        return "object";
    }else {
        return undefined;
    }
}
const getHoliday = (holidays,date) => {
    if(!holidays) return false
    let hs = []
    for(let h of holidays) {
        if(h.begin <= date && h.end>=date) {
            hs.push(h)
        }
    }
    if(hs.length>0) {
        let ret = hs[0]
        for(let h of hs) {
            if(h.type < ret.type) {
                ret = h
            }
        }
        return ret
    }        
    return false
}
const getLocaleDate = (date,days)=>{
    if(!date) {
        date = new Date()
    }
    days && days > 0 && date.setDate(date.getDate() + days)
    return date.getFullYear()+"-"+((date.getMonth()+1)<10?('0'+(date.getMonth()+1)):(date.getMonth()+1))+'-'+(date.getDate()<10?('0'+date.getDate()):date.getDate())
}
const getTime = (time)=> {
    let d = new Date(time)
    return d.toLocaleTimeString()
}
const Weeks = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday']
const Weeks1 = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']
const holidayColors = ['#B22222','#006400','#32CD32','#B22222']

const minuteToTime = (m) => {
    let h = Math.floor(m / 60)
    let mm = m % 60
    return h + ":" + (mm > 10 ? mm : "0" + mm);
}

const orderList = (list,key,order) => {
    let newlist = []    
    for(let i = 0;i<list.length;i++) {           
        if(newlist.length == 0) {
            newlist.push(list[i])
        }else {
            let j = 0
            for(;j<newlist.length;j++) {
                if(order == 0 && list[i][key]<newlist[j][key]) {
                    newlist.splice(j,0,list[i])
                    break
                }else if(order == 1 && list[i][key]>newlist[j][key]) {
                    newlist.splice(j,0,list[i])
                    break
                }
            }
            if(j == newlist.length) {
                newlist.push(list[i])
            }
        }
    }
    return newlist
}

export { apiResult, formToJson, sessionSet, sessionGet, cacheSet, cacheGet, 
    getUserSession, setUserSession, 
    getVarsFromString, formatDate, 
    copyTextToClipboard,
    getTeacherSession,setTeacherSession,
    getMemberSession,setMemberSession,
    getDatesInRange ,checkInLesson,getLocaleDate,whatType,
    getHoliday,holidayColors,Weeks,Weeks1,minuteToTime,orderList,getTime
}