import { Alert, Box, Container, Grid, Paper, Snackbar, Stack, Typography, } from "@mui/material"
import React, { useEffect, useRef, useState } from "react"

import { useParams, useNavigate } from "react-router"
import apis from "../../api";
import { LOGO_SLOGAN_COLOR, LOGO_TEXT_COLOR } from "../../app/config";
import { NoData, Title } from "../../Component/MuiEx";
import { apiResult, getTeacherSession, checkInLesson, whatType } from "../../Utils/Common";

import LessonSideMenu from "../fragement/LessonSideMenu";
import SlideContentItem from "../fragement/SlideContentItem";
import LessonBar from "./fragement/LessonBar";
import MembersSideMenu from "./fragement/MembersSideMenu";

export default function Lesson() {
    const [contentIdx, setContentIdx] = useState(0)
    const [index, setIndex] = useState(0)
    const [error, setError] = useState()
    const [lesson, setLesson] = useState()
    const [schedule, setSchedule] = useState()
    const [contents, setContents] = useState()
    const [hintMsg, setHintMsg] = useState()
    const params = useParams()
    const showWindow = useRef()
    const listRef = useRef()
    const navigate = useNavigate()
    const session = getTeacherSession(apis)
    const changes = useRef([])
    const [homeworks, setHomeworks] = useState()
    const currentMember = useRef()
    if (!session) {
        navigate('/teacher/signin')
    }
    const initPage = useRef(false)
    useEffect(() => {

        if (initPage.current) {
            return
        }
        initPage.current = true
        apis.teacherLesson(params.scheduleid).then(ret => {
            apiResult(ret, data => {
                if (data.lesson) {
                    for (let i = 0; i < data.lesson.lesson_pages.length; i++) {
                        data.lesson.lesson_pages[i].content = data.lesson.lesson_pages[i].content.length > 0 ? JSON.parse(data.lesson.lesson_pages[i].content) : []
                    }
                    setContents(data.lesson.lesson_pages)
                    setLesson(data.lesson)
                }
                setContentIdx(0)
                setSchedule(data.schedule)
                setIndex(0)

                // if (checkInLesson(data.schedule.sdate, Number(data.schedule.begintime), Number(data.schedule.duration), 130) === 0) {
                //     setInLesson(true)
                //     inLessonRef.current = true
                //     if (data.schedule.class_schedule_checkin) {
                //         if (data.teachertype === 1 && data.schedule.class_schedule_checkin.teacher) {
                //             setCheckIn(true)
                //             //window.setInterval(handleInterval,1000)
                //             token.current = data.schedule.class_schedule_checkin.token
                //         } else if (data.teachertype === 2 && data.schedule.class_schedule_checkin.assistant) {
                //             setCheckIn(true)
                //             // window.setInterval(handleInterval,1000)
                //             token.current = data.schedule.class_schedule_checkin.token
                //         }
                //     }
                // } else {
                //     setInLesson(false)
                // }
            }, setError)
        })
        window.setInterval(updateHomework, 5000)
        window.onunload = () => {
            if (changes.length > 0) {
                updateHomework()
            }
        }
    }, [])
    // const handleInterval = ()=>{
    //     let time = new Date().getTime()        
    //     inLessonRef.current && modelRef.current == 1 && apis.teacherLessonSyncGet(params.scheduleid,hex_md5(token.current+time),time).then(ret=>{
    //         apiResult(ret,data=>{                           
    //             data.page != contentIdx && setContentIdx(data.page)
    //             data.section != index && setIndex(data.section)
    //             data.page == maxContentIdx && data.section>index && setMaxIndex(data.section)
    //             data.page > maxContentIdx && setMaxContentIdx(data.page) && setMaxIndex(data.section)                
    //         },setError)
    //     })
    // }
    const onPartItemClick = (item, idx) => {
        setContentIdx(idx)
        setIndex(0)
        contentSwitch(idx, 0)
    }
    const handleSectionClick = (idx, item) => {
        idx !== index && setIndex(idx)
        idx !== index && contentSwitch(contentIdx, idx)
    }
    const contentSwitch = (contentidx, idx) => {
        // let time = new Date().getTime()        
        // inLesson && apis.teacherLessonSyncPut(params.scheduleid,hex_md5(token.current+time),time,contentidx,idx).then(ret=>{
        //     apiResult(ret,data=>{

        //     },setError)
        // })
        showWindow.current?.handleChange(contentidx, idx)
    }
    window.onkeydown = (e) => {
        if (e.target.nodeName !== 'BODY') {
            return
        }
        if (e.keyCode === 32) {
            // setModel(model == 1?2:1)
            // modelRef.current = modelRef.current == 1?2:1
            showWindow.current?.setContentCovered()
            e.preventDefault()
        } else if (e.keyCode === 39 || e.keyCode === 40) {
            let idx = index < contents[contentIdx].content.length - 1 ? (index + 1) : index
            idx !== index && setIndex(idx)
            contentSwitch(contentIdx, idx)
            e.preventDefault()
        } else if (e.keyCode === 37 || e.keyCode === 38) {
            let idx = index > 1 ? index - 1 : 0
            setIndex(idx)
            contentSwitch(contentIdx, idx)
            e.preventDefault()
        } else if (e.keyCode === 33) {
            let newIdx = contentIdx > 0 ? contentIdx - 1 : contentIdx
            setContentIdx(newIdx)
            setIndex(0)
            contentSwitch(newIdx, 0)
            e.preventDefault()
        } else if (e.keyCode === 34) {
            let newIdx = contentIdx < contents.length - 1 ? contentIdx + 1 : contentIdx
            setContentIdx(newIdx)
            setIndex(0)
            contentSwitch(newIdx, 0)
            e.preventDefault()
        }

    }

    const handleExit = () => {
        if (window.confirm("Exit the lesson?")) {
            showWindow.current?.close()
            window.close()
        }
    }
    const onItemExpand = (idx, item) => {

    }
    const handleTextClick = (idx) => {
        showWindow.current?.handleTextClick && showWindow.current.handleTextClick(idx)
    }

    const handleLessonShow = () => {
        showWindow.current = window.open('/teacher/lessonshow', 'lessonshow')
        window.afterShowWindowLoad = () => {
            showWindow.current.setContents(contents)
            showWindow.current.handleChange(contentIdx, index)
            currentMember.current && showWindow.current.setItemContext(currentMember.current?.homework)
        }
    }
    const getItemContextIndex = (id) => {
        if (currentMember.current?.homework) {
            for (let i = 0; i < currentMember.current.homework.length; i++) {
                let item = currentMember.current.homework[i]
                if (item.item_id === id) {
                    return i
                }
            }
        }
        return -1
    }
    const changed = useRef(false)
    const handleItemChange = (id, name, value) => {
        changed.current = true
        let idx = getItemContextIndex(id)
        let data = null
        if (idx < 0) {
            data = { item_id: id, schedule_id: params.scheduleid, member_id: currentMember.current.id, content: { [name]: value } }
            currentMember.current.homework.push(data)
        } else {
            if (whatType(currentMember.current.homework[idx].content) === 'object') {
                currentMember.current.homework[idx].content[name] = value
            } else {
                currentMember.current.homework[idx].content = { [name]: value }
            }
            data = currentMember.current.homework[idx]
        }
        showWindow.current?.setItemContext(currentMember.current?.homework)
        let ch = false
        for (let item of changes.current) {
            if (item.item_id === data.item_id && item.member_id == data.member_id) {
                item.content = data.content
                ch = true
            }
        }
        if (!ch) {
            changes.current.push(data)
        }

    }
    const updateHomework = () => {
        if (Object.keys(changes.current).length > 0) {
            changed.current = false
            apis.teacherHomeworkPost({ data: changes.current }).then(ret => {
                apiResult(ret, data => {
                    if (!changed.current) {
                        changes.current = []
                    }
                }, err => setError("Fail to auto save the update"))
            })
        }
    }
    const jsonHomework = (data) => {
        for (let h of data) {
            h.content = whatType(h.content) === 'string' && h.content.length > 1 ? JSON.parse(h.content) : undefined
        }
        return data
    }
    const handleMemberClick = (member) => {
        if (!member.homework) {
            apis.teacherHomework(params.scheduleid, member.id).then(ret => {
                apiResult(ret, data => {
                    member.homework = jsonHomework(data)
                    currentMember.current = member
                    setHomeworks(member.homework)
                }, setError)
            })
        } else {
            currentMember.current = member
            setHomeworks(member.homework)
        }
        showWindow.current?.setItemContext(currentMember.current?.homework)
    }
    return <Stack direction="row">
        {contents && lesson && <LessonSideMenu onExit={handleExit} currentIdx={contentIdx} menus={contents} onMenuItemClick={onPartItemClick} title={lesson.lessonNo + ":" + lesson.name} />}
        <Container maxWidth="hg">
            {error && <Alert severity="error" onClose={() => setError()}>{error}</Alert>}
            {lesson && <Box>
                <LessonBar sx={{ position: 'relative', right: '0px', zIndex: '100' }}
                    onLessonShow={handleLessonShow}
                    onCovered={() => { showWindow.current?.setContentCovered() }}
                    onWidthChange={(v) => showWindow.current?.setWidth(v)}
                />
                {contentIdx >= 0 && <Box ref={listRef} sx={{ m: 2, height: '92vh', overflow: "auto" }}>
                    <Box sx={{ width: "95%" }}><Grid container spacing={2} sx={{ p: 2 }}>
                        {contents && contents.length > 0 && contents[contentIdx].content && contents[contentIdx].content.map((item, idx) => {
                            return <Grid item key={idx} xs={item.grid ? Number(item.grid) : 12}>
                                <SlideContentItem itemContexts={homeworks} onChange={handleItemChange} viewer={'teacher'} container={listRef.current} sx={{ width: '100%' }} onTextClick={handleTextClick} item={item} idx={idx} selected={index === idx} onClick={(idx, item) => handleSectionClick(idx, item)} onExpand={onItemExpand} />
                            </Grid>
                        })}
                        <Grid item xs={12} sx={{ height: '30vh' }}>
                            <Box sx={{ height: '30vh' }}></Box>
                        </Grid>
                    </Grid></Box>
                </Box>}
            </Box>}
            {!lesson && <Paper sx={{p:2,mt:2,bgcolor:LOGO_TEXT_COLOR,color:"#fff"}}>
                <Typography variant="h2">{schedule && schedule.course?.name}</Typography>
                <Typography variant="h4" sx={{mt:2}}>Lesson {schedule && schedule.lesson}</Typography>
                <Typography variant="h6" sx={{mt:2}}>No lesson page</Typography>
            </Paper>}
            {hintMsg && <Snackbar anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }} open={hintMsg ? true : false} autoHideDuration={3000} onClose={() => setHintMsg()}>
                <Alert onClose={() => { setHintMsg() }} severity="success" sx={{ width: '100%' }}>{hintMsg}</Alert>
            </Snackbar>}

        </Container>
        {schedule && <MembersSideMenu scheduleid={params.scheduleid}
            duration={schedule?.duration}
            begintime={schedule?.begintime}
            sdate={schedule?.sdate}
            onMemberClick={handleMemberClick}
        />}
    </Stack>
}