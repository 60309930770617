import { useState } from 'react';
import { Avatar, Alert } from '@mui/material';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import CssBaseline from '@mui/material/CssBaseline';
import apis from '../../api';
import { apiResult, formToJson, getTeacherSession, sessionGet, setTeacherSession, setUserSession } from '../../Utils/Common';
import { useNavigate } from 'react-router';
import { PasswordTextField } from '../../Component/MuiEx';

export default function TeacherSignin() {
  const [fieldErrors, setFieldErrors] = useState()
  const [error, setError] = useState()
  const [rememberMe, setChecked] = useState(false)
  const navigate = useNavigate()
  const session = getTeacherSession(apis)
  const handleChecked = (event) => {
    setChecked(event.target.checked)
  }
  const handleSubmit = (event) => {
    setFieldErrors()
    setError()
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    const postdata = formToJson(data)
    postdata.email = postdata.email.trim()
    apis.teacherSignIn(postdata).then(ret => {
      apiResult(ret, (data) => {
        setTeacherSession(data, rememberMe)                
        navigate('/teacher/home')
      }, setError, setFieldErrors)
    })
  }

  return (<>
    <Container component="main" maxWidth="xs" sx={{ marginTop: 10 }}>
      <CssBaseline />
      <Box sx={{ marginTop: 12, display: 'flex', flexDirection: 'column', alignItems: 'center', }}>
      <img src="/logo.png" style={{ height: "50px" }} />     
        <Typography component="h1" variant="h5" sx={{mt:4}}>Teacher sign in </Typography>
        <Box component="form" onSubmit={handleSubmit} sx={{ mt: 1 }}>
          <TextField margin="normal"  name="email" required fullWidth id="email" label="Email Address" autoFocus error={fieldErrors && fieldErrors.email ? true : false}
              helperText={fieldErrors && fieldErrors.email ? fieldErrors.email : ''}/>
          <PasswordTextField sx={{width:'100%',marginTop:2,marginBottom:2}} required fullWidth name="passwd" label="Password" type="password" id="passwd" autoComplete="new-password" />          
          <FormControlLabel
            control={<Checkbox value="remember" color="primary" onChange={handleChecked} />}
            label="Remember me"
          />
          {error && <Alert severity="error">{error}</Alert>}
          <Button type="submit" fullWidth variant="contained" sx={{ mt: 3, mb: 2 }} > Sign In </Button>          
        </Box>
      </Box>
    </Container>
  </>
  );
}
