import { ArrowForward, ArrowRight, Check, Message, Person, PersonOutline } from "@mui/icons-material"
import { Avatar, Box, Button, Card, CardActions, CardContent, CardHeader, Chip, Grid, IconButton, Paper, Stack, Typography } from "@mui/material"
import { useEffect, useState } from "react"

import apis from "../../api"
import { LOGO_ICON_COLOR, LOGO_TEXT_COLOR } from "../../app/config"
import { apiResult, getLocaleDate, getMemberSession, getTeacherSession, minuteToTime, orderList, Weeks1 } from "../../Utils/Common"
import { BorderLinearProgress } from "../../Component/MuiEx"
import { useNavigate } from "react-router"

const MemberHome = () => {
    const [classes, setClasses] = useState()    
    const [error, setError] = useState()
    const session = getMemberSession(apis)
    const navigate = useNavigate()
    if(!session) {
        navigate("/member/signin")
    }
    let initPage = false
    useEffect(() => {
        if (!initPage) {
            initPage = true
            apis.memberClasses().then(ret => {
                apiResult(ret, data => {
                    let pages = 0
                    for (var cidx in data) {
                        let cls = data[cidx].class
                        if (cls.course) {
                            for (let i in cls.course.lessons) {
                                let lesson = cls.course.lessons[i]
                                pages += lesson.lesson_pages ? lesson.lesson_pages.length : 0
                            }
                        }
                        data[cidx].pages = pages
                        data[cidx].mypoints = 0
                        data[cidx].mypass = 0
                        data[cidx].mypages = 0
                        if (data[cidx].member_pages && data[cidx].member_pages.length > 0) {
                            for (let i in data[cidx].member_pages) {
                                data[cidx].mypoints += data[cidx].member_pages[i].points
                                data[cidx].mypass += data[cidx].member_pages[i].pass
                                data[cidx].mypages++
                            }
                        }
                        orderList(data[cidx].member_pages, "pageNo", 1)
                    }
                    setClasses(data)
                }, setError)
            })
        }
    }, [])

    const scheduleToShow = (schedules) => {
        let today = getLocaleDate()
        let ret = { isToday: false, wdate: "N", time: "No Schedule", status: 1 }
        if (schedules && schedules.length > 0) {
            let s = 0
            for (var ss in schedules) {
                if (s == 0 || s.sdate > schedules[ss].sdate) {
                    s = schedules[ss]
                }
            }
            ret.wdate = Weeks1[s.wod]
            if (s.sdate == today) {
                ret.isToday = true
                ret.time = String(minuteToTime(s.begintime)) + " - " + String(minuteToTime(s.begintime + s.duration))
            } else {
                ret.time = Weeks1[s.wod] + " " + String(minuteToTime(s.begintime)) + " - " + String(minuteToTime(s.begintime + s.duration))
            }
            ret.status = s.status
        }
        return ret
    }
    return <Box sx={{ p: 4 }}>
        <Typography variant="h4" sx={{ mb: 2 }}>My Classes</Typography>
        <Grid container spacing={2}>
            {classes && classes.map((cls, idx) => {
                var schedule = scheduleToShow(cls.class.class_schedules)
                return <Grid item xs={12} sm={12} md={4} lg={3} key={idx}>
                    <Card sx={{ }}>
                        <CardHeader sx={{ bgcolor: "#ccc" }}
                            avatar={
                                <Avatar sx={{ bgcolor: schedule.isToday ? LOGO_TEXT_COLOR : LOGO_ICON_COLOR }} aria-label="recipe">{schedule.wdate?.substring(0, 1)}</Avatar>
                            }
                            title={cls.class.name}
                            subheader={cls.class.course?.name}
                        />
                        <CardContent>
                            <Box sx={{ overflow: "auto",p:0.2 }}>
                                <Typography sx={{ fontSize: "12px",mb:1, width: "fit-content" }}>Assigned: <b>{cls.mypages}</b>  ({Math.floor(cls.mypages * 100 / cls.pages).toFixed(0)}%)</Typography>
                                <BorderLinearProgress variant="determinate" value={Number(cls.mypages * 100 / cls.pages)} sx={{ mb: 1 }} />
                                <Typography sx={{ fontSize: "12px",mt:1,mb:1, width: "fit-content" }}>Finished: <b>{cls.mypass}</b>  ({Math.floor(cls.mypass * 100 / cls.pages).toFixed(0)}%)</Typography>
                                <BorderLinearProgress variant="determinate" value={Number(cls.mypass * 100 / cls.pages)} />
                                <Typography sx={{ fontSize: "12px", width: "fit-content",mb:1,mt:1}}>Recent Chapters</Typography>
                                <Box sx={{ p: 1,border:"1px solid #eee" }}>
                                    {cls.member_pages.map((mypage, idx) => {
                                        if (idx > 5) return null
                                        return mypage.lesson_page && <Box sx={{ display: "flex", flexDirection: "row",pt:0.5}} key={idx}>
                                            <Typography sx={{ width: "fit-content", fontSize: "12x", flex: "1 1 30%" }}>{mypage.lesson_page.title}{mypage.pass == 1 && <Check fontSize="small" />}</Typography>
                                            <IconButton size="small" onClick={()=>{window.open("/member/class/lesson/"+cls.class.id+"/"+mypage.page_id)}}><ArrowForward fontSize="small" /></IconButton>
                                        </Box>
                                    })}
                                </Box>                                
                            </Box>
                            <Box sx={{ display: "flex",mt:1, flexDirection: "row" }}>
                                {cls.class.teacher && <Chip variant="outlined" sx={{ margin: "3px" }} icon={<Person />} label={cls.class.teacher?.name}></Chip>}
                                {cls.class.assistant && <Chip variant="outlined" sx={{ margin: "3px" }} icon={<PersonOutline />} label={cls.class.assistant?.name}></Chip>}
                            </Box>
                            <Box sx={{ display: "flex",mt:1, flexDirection: "row" }}>
                                <Typography color="text.secondary" sx={{ flex: "1 1 30%" }} variant="body2">{cls.class.area ? cls.class.area.name : "York Mills"}</Typography>
                                <Typography color="text.secondary" sx={{ flex: "1 1 40%" }} variant="body2">{schedule.time}</Typography>
                                {schedule.status != 1 && <Typography color="text.secondary" sx={{ color: "red", flex: "1 1 30%" }} variant="body2">Postpone</Typography>}
                            </Box>
                        </CardContent>
                        <CardActions disableSpacing>
                            {cls.class.messages.length>0 && <Button variant="contained" color="error" size="small" startIcon={<Message />}>{cls.class.messages.length}</Button>}
                            <Button sx={{marginLeft:"auto"}} endIcon={<ArrowRight />} size="small" variant={schedule.isToday ? "contained" : "outlined"} onClick={() => { window.open(cls.class.course.coursetype == 'none'?("/member/schedule"):("/member/class/" + cls.class_id), "class_detail") }}>Enter class</Button>
                        </CardActions>
                    </Card>
                </Grid>
            })}
        </Grid>
        {/* <List>
            {schedules && schedules.map((sch,idx)=>{
                return <ListItem key={idx}>
                    <Paper sx={{p:2}}>
                        <Typography variant="h6">{sch.class?sch.class.name:(sch.member?sch.member.name:"unkown")}</Typography>
                        <Typography variant="h6">{sch.teacher?sch.teacher.name:'No teacher'}</Typography>
                        <Typography variant="h6">{sch.assistant?sch.assistant.name:'No assistant'}</Typography>
                        <Typography variant="h6">{sch.sdate}</Typography>
                        <Typography variant="h6">{sch.begintime}</Typography>
                        <Typography variant="h6">{sch.duration}</Typography>
                        <Typography variant="h6">{sch.note}</Typography>
                    </Paper>
                </ListItem>
            })}
        </List> */}
    </Box>
}
export default MemberHome