import { Alert, Box, Container, Grid, Paper, Snackbar, Stack, Typography, } from "@mui/material"
import React, { useEffect, useRef, useState } from "react"

import { useParams, useNavigate } from "react-router"
import apis from "../../api";
import { apiResult, getTeacherSession, whatType, } from "../../Utils/Common";

import SlideContentItem from "../fragement/SlideContentItem";
import ClassMemberList from "./fragement/ClassMemberList";

export default function PageHome() {
    const [error, setError] = useState()
    const [page, setPage] = useState()
    const [members, setMembers] = useState()
    const [hintMsg, setHintMsg] = useState()
    const params = useParams()
    const navigate = useNavigate()
    const session = getTeacherSession(apis)

    const [homeworks, setHomeworks] = useState()
    const currentMember = useRef()
    const currentMIdx = useRef()
    const changed = useRef(false)
    if (!session) {
        navigate('/teacher/signin')
    }
    const initPage = useRef(false)
    const { pageid, classid } = params
    useEffect(() => {
        if (initPage.current) {
            return
        }
        initPage.current = true
        apis.teacherClassPage(classid, pageid).then(ret => {
            apiResult(ret, data => {
                if (data.page) {
                    data.page.content = data.page.content.length > 0 ? JSON.parse(data.page.content) : []
                    setPage(data.page)
                }
                if (data.members) {
                    for (let midx in data.members) {
                        let m = data.members[midx]
                        if (m.member_pages.length > 0) {
                            m.member_page = m.member_pages[0]
                            m.member_page.content = m.member_page.content && m.member_page.content.length > 0 ? JSON.parse(m.member_page.content) : []
                            // m.member_page.comment = m.member_page.comment && m.member_page.comment.length > 0 ? JSON.parse(m.member_page.comment) : []
                        } else {
                            m.member_page = null
                        }
                        let news = 0
                        for(let msgidx in m.messages) {
                            if(m.messages[msgidx].status == 0 && m.messages[msgidx].sender == 1) {
                                news++
                            }
                        }
                        m.newmsgs = news;
                    }
                    setMembers(data.members)
                }

            }, setError)
        })
        window.setInterval(postUpdate, 5000)
        window.onunload = () => {
            postUpdate()
        }
    }, [])
    const getItemContextIndex = (id) => {
        if (currentMember.current?.member_page?.content) {
            for (let i = 0; i < currentMember.current.member_page.content.length; i++) {
                let item = currentMember.current.member_page.content[i]
                if (item.item_id === id) {
                    return i
                }
            }
        }
        return -1
    }
    const handleItemChange = (id, name, value) => {
        if (!currentMember.current) return
        changed.current = true
        let idx = getItemContextIndex(id)
        let data = null
        if (idx < 0) {
            data = { item_id: id, content: { [name]: value } }
            currentMember.current.member_page.content.push(data)
        } else {
            if (whatType(currentMember.current.member_page.content[idx].content) === 'object') {
                currentMember.current.member_page.content[idx].content[name] = value
            } else {
                currentMember.current.member_page.content[idx].content = { [name]: value }
            }
        }
    }
    const postUpdate = () => {
        if (currentMember.current && changed.current) {
            apis.teacherPostPage({ id: currentMember.current.member_page.id, content: JSON.stringify(currentMember.current.member_page.content) }).then(ret => {
                apiResult(ret, data => {
                    setHintMsg("Save successfully")
                    changed.current = false
                    let mm = [...members]
                    mm[currentMIdx].member_page.content = currentMember.current.member_page.content
                    setMembers(mm)
                }, setError)
            })
        }
    }

    const handleMemberClick = (idx) => {
        if (idx >= 0) {
            var member = members[idx]
            currentMember.current = member
            currentMIdx.current = idx
            if (member.member_page && member.member_page.content) {
                setHomeworks(member.member_page.content)
            }
        }else {
            currentMember.current = undefined
            setHomeworks()
        }
    }
    return <Stack direction="row">
        <Paper sx={{ mr: 1, height: "100vh", width: "100%", overflow: "auto" }}>
            {page && <Grid container spacing={2} sx={{ p: 2 }}>
                {page.content && page.content.length > 0 && page.content.map((item, idx) => {
                    return <Grid item key={idx} xs={item.grid ? Number(item.grid) : 12}>
                        <SlideContentItem itemContexts={homeworks} onChange={handleItemChange} viewer={'teacher'} sx={{ width: '100%' }} item={item} idx={idx} />
                    </Grid>
                })}
                <Grid item xs={12} sx={{ height: '30vh' }}>
                    <Box sx={{ height: '30vh' }}></Box>
                </Grid>
            </Grid>
            }
        </Paper>
        {members && <ClassMemberList members={members} title={String(page.pageNo) + "-" + page.title} open={true}
            onMemberClick={handleMemberClick} page={page} classid={classid} errorNotify={setError} hint={setHintMsg}
        />}
        {(hintMsg || error) && <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'center' }} open={hintMsg || error ? true : false} autoHideDuration={3000} onClose={() => { setHintMsg(); setError() }}>
            <Alert onClose={() => { setHintMsg(); setError() }} severity={hintMsg ? "success" : "error"} sx={{ width: '100%' }}>{hintMsg || error}</Alert>
        </Snackbar>}
    </Stack>
}