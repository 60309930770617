import { Box, Button, Divider, Paper, Stack, TextField, Typography } from "@mui/material"
import { LOGO_ICON_COLOR, LOGO_TEXT_COLOR } from "../../app/config"
import { CopyBlock, vs2015 } from "react-code-blocks"
import { useEffect, useState } from "react"
import { SingleSelector } from "../../Component/MuiEx"
import { apiResult, getLocaleDate, getTime } from "../../Utils/Common"
import CodeEditor from "./CodeEditor"
import { FiberManualRecord } from "@mui/icons-material"

const Messages = (props) => {
    const { comments, pageid, apis, onPosted,onChangeStatus, errorNotify,isTeacher } = props    
    const [comment, setComment] = useState()
    const [rows,setRows] = useState(2)    
    const [type, setType] = useState("text")
    const [commentList, setCommentList] = useState(comments)    
    useEffect(() => {          
        setCommentList(comments)        
    }, [pageid, comments])
    const handleCommentChange = (v) => {
        setComment(v)
    }
    const handleSubmit = () => {        
        let nc = { sender: isTeacher?0:1, content: comment, type: type, page_id:pageid }        
        comment && isTeacher && apis.teacherPostMessage(nc).then(ret => {
            apiResult(ret, data => {
                let cc = [...commentList]
                cc.unshift(data)
                setCommentList(cc)
                setComment("")
                setRows(3)
                onPosted(cc)
            }, errorNotify ? errorNotify : (e) => { })
        })
        comment && !isTeacher && apis.memberPostMessage(nc).then(ret => {
            apiResult(ret, data => {
                let cc= [...commentList]
                cc.unshift(data)
                setCommentList(cc)
                setComment("")
                setRows(3)
                onPosted(cc)
            }, errorNotify ? errorNotify : (e) => { })
        })
    }
    const updateMessageStatus = (idx,status)=>{
        let nc = { id:commentList[idx].id,status }        
        status != commentList[idx].status && (isTeacher ? apis.teacherPutMessage(nc).then(ret => {
            apiResult(ret, data => {
                let cc = [...commentList]
                onChangeStatus&&onChangeStatus(idx,cc[idx].status,status)
                cc[idx].status = status
                setCommentList(cc)                
            }, errorNotify ? errorNotify : (e) => { })
        }):apis.memberPutMessage(nc).then(ret => {
            apiResult(ret, data => {
                let cc= [...commentList]
                onChangeStatus&&onChangeStatus(idx,cc[idx].status,status)
                cc[idx].status = status
                setCommentList(cc)                
            }, errorNotify ? errorNotify : (e) => { })
        }))
    }
    return <Box sx={{ width: "100%",}}>
        <Typography variant="body" component={"div"}>Message Board</Typography>
        {type == "text" && <TextField onFocus={()=>{setRows(9)}}  fullWidth multiline sx={{ mt: 2, width: "100%", wrapLines: true }} rows={rows} placeholder="post something" value={comment} onChange={(e) => handleCommentChange(e.target.value)} />}
        {type == "code" && <CodeEditor onFocus={()=>{setRows(9)}} sx={{ mt: 2, width: "100%"}} height={String(rows*25) + "px"} value={comment} onChange={(v) => handleCommentChange(v)} />}
        <Stack direction={"row"}>
            <SingleSelector items={["Code", "Text"]} values={["code", "text"]} defaultValue={type} onChange={(n, v) => setType(v)} />
            <Button sx={{ mt: 1 ,width:"200px"}} variant="contained" disabled={!comment} size="small" onClick={handleSubmit}>Post</Button>
        </Stack>
        <Box sx={{ overflow:"auto",mt:2}}>
            {commentList && commentList.map((item, idx) => {
                return <Box sx={{ width:"100%",fontSize:"18px",mt:1,cursor:"pointer"}} key={idx} onClick={()=>{item.status == 0 && ((isTeacher && item.sender == 1) || (!isTeacher && item.sender == 0) ) && updateMessageStatus(idx,1)}}>
                    <Typography sx={{fontSize:'10px',color:"#0006"}}>{item.sender == 0?"Teacher":"Student"} @ {getLocaleDate() == getLocaleDate(new Date(item.posttime))?getTime(item.posttime):getLocaleDate(new Date(item.posttime))} {item.status == 0 && ((isTeacher && item.sender == 1) || (!isTeacher && item.sender == 0) ) ?<font color="red">(new)</font>:""}</Typography>
                    {item.type == "code" ? <Box sx={{maxHeight:"400px",overflow:"auto",fontSize:"12px"}}><CopyBlock text={item.content} theme={vs2015} language={'python'} showLineNumbers={true} wrapLines={true} ></CopyBlock></Box> :
                    <Box sx={{whiteSpace:"normal",maxHeight:"400px",overflow:"auto",wordBreak:"break-all",color:LOGO_TEXT_COLOR,fontSize:"16px"}}>{item.content}</Box>}                    
                </Box>
            })}
        </Box>
        
    </Box>
}

export default Messages