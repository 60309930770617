import {useState} from 'react';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import { Box, ListItem, ListItemAvatar, ListItemText,  } from '@mui/material';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { Avatar } from '@mui/material';
import { Drawer, Title } from '../../Component/MuiEx';
import { LOGO_ICON_COLOR, LOGO_TEXT_COLOR } from '../../app/config';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import { useNavigate } from 'react-router';
const LessonSideMenu = (props)=>{
    const {title,menus,onMenuItemClick,currentIdx,onExit} = props
    const [open, setOpen] = useState(false);      
    const navigate = useNavigate()
    const toggleDrawer = () => {
        setOpen(!open);
    };

    return <Drawer variant="permanent" open={open} >
        <Toolbar
            sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'flex-end',
                px: [1],
                background: 'white',
                color: LOGO_ICON_COLOR,
            }}>
            <Box sx={{width:'180px',display:open?'block':'none'}}><img src="/logo.png" style={{ width: '100%' }} /></Box>
            <IconButton onClick={toggleDrawer} sx={{ background: 'white' }}>
                <ChevronLeftIcon sx={{ display: open ? 'block' : 'none' }} />
                <Avatar src="/icon.webp" sx={{ display: open ? 'none' : 'block' }} />
            </IconButton>
        </Toolbar>
        <Divider />     
        
        <List component="nav" sx={{ p: 0}} subheader={open &&<Title sx={{pl:2,pt:2,pb:1}}>{title}</Title>}>            
            {menus && menus.map((item, idx) => {
                return <ListItem selected={idx === currentIdx} key={idx} onClick={() => { onMenuItemClick && onMenuItemClick(item, idx) }} sx={{ pt: 1, pb: 1,cursor:'pointer' }}>
                    <ListItemAvatar sx={{ display: open ? 'none' : 'block' }} title={item.title}>
                        <Avatar sx={{ bgcolor: idx === currentIdx ? LOGO_ICON_COLOR : LOGO_TEXT_COLOR }}>{item.pageNo}</Avatar>
                    </ListItemAvatar>
                    <ListItemText sx={{ color: LOGO_TEXT_COLOR }}>{item.title}</ListItemText>
                </ListItem>
            }
            )}
            <Divider sx={{mt:2}}/>
            <ListItem onClick={() => {onExit()}} sx={{ pt: 1, pb: 1 ,cursor:'pointer'}}>
                    <ListItemAvatar  sx={{ display: open ? 'none' : 'block' }} title="Exit lesson">
                        <Avatar sx={{ bgcolor: 'gray' }}><ExitToAppIcon /></Avatar>
                    </ListItemAvatar>
                    <ListItemText sx={{ color:'gray' }}>Exit lesson</ListItemText>
                </ListItem>
        </List>
    </Drawer>
}
export default LessonSideMenu