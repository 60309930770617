import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { useEffect,useState } from 'react';

export default function SimpleTable(props) {
    const {datas,size,color}=props
    const StyledTableCell = styled(TableCell)(({ theme }) => ({
        [`&.${tableCellClasses.head}`]: {
            backgroundColor: color?color:'#093D65',
            color: theme.palette.common.white,
            fontSize:size?size:'20px'
        },
        [`&.${tableCellClasses.body}`]: {
            fontSize: size?size:"20px",
        },
    }));
    
    const StyledTableRow = styled(TableRow)(({ theme }) => ({
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.action.hover,
            fontSize:size?size:'20px'
        },
        // hide last border
        '&:last-child td, &:last-child th': {
            border: 0,
        },
    }));
    
    const [items,setItems] = useState([])
    useEffect(()=>{
        if(datas) {
            try {
                let obj = JSON.parse(datas)
                setItems(obj)
            }catch(e) {
                console.log(e.message)
                setItems([])
            }
        }
    },[datas])
    return (
        <TableContainer component={Paper}>
            <Table sx={{ minWidth: 700 }} aria-label="customized table">
                <TableHead>
                    <TableRow>
                        {items && items.length>0 && items[0].length>0 && items[0].map((item,idx)=>{
                            return <StyledTableCell key={idx} align="left">{item}</StyledTableCell>
                        })}                        
                    </TableRow>
                </TableHead>
                <TableBody>
                    {items && items.length>0 && items.map((row,idx) => (                        
                        (idx > 0) && <StyledTableRow key={idx}>
                            {row.length>0 && row.map((item,idx) => (
                                idx == 0?<StyledTableCell key={idx} idx={idx} component="th" scope="row" >
                                    {item}
                                </StyledTableCell>:<StyledTableCell key={idx} idx={idx} align="left">
                                    {item}
                                </StyledTableCell>
                            ))}
                        </StyledTableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
}