import { Dialog,DialogTitle,Alert,DialogContent,DialogContentText,TextField,Button,DialogActions } from "@mui/material"
import { useEffect, useState } from "react"

const BuyGiftDialog = (props) =>{
    const {onSubmit} = props
    const [open,setOpen] = useState()
    const [note,setNote] = useState()
    const [error,setError] = useState()
    const [amount,setAmount] = useState()
    useEffect(()=>{setOpen(props.open)},[props.open])
    const handleClose =()=>{
        setOpen(false)
    }    
    const handleSubmit = ()=>{
        onSubmit && onSubmit(amount,note,(err)=>{
            if(err) {
                setError(err)
            }else {
                setOpen(false)
            }
        })
    }
    return <Dialog open={open} onClose={handleClose}>
    <DialogTitle>Buy gift</DialogTitle>
    <DialogContent>
        <DialogContentText>Please input gift information and how much points to buy it</DialogContentText>
        <TextField autoFocus margin="normal" label="Gift Description" onChange={(e)=>{setNote(e.target.value)}} type="text" fullWidth/>
        <TextField autoFocus margin="normal" label="Points" type="number" onChange={(e)=>{setAmount(e.target.value)}} fullWidth/>
        {error && <Alert severity="error" onClose={() => setError()}>{error}</Alert>}
    </DialogContent>
    <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        <Button onClick={handleSubmit}>Ok</Button>
    </DialogActions>
</Dialog>
}

export default BuyGiftDialog