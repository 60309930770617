
import {   ChevronLeft, ChevronRight, ExitToApp, } from "@mui/icons-material"
import { Box, Paper, FormControlLabel, IconButton, Rating, Stack, Switch,  Toolbar, Typography } from "@mui/material"
import { useState } from "react"

import apis from "../../../api"
import { LOGO_TEXT_COLOR } from "../../../app/config"
import { Drawer } from "../../../Component/MuiEx"
import Comments from "../../fragement/Comments"
import { getMemberSession } from "../../../Utils/Common"
import Messages from "../../fragement/Messages"

const PageSide = (props) => {
    const { page,errorNotify,title } = props    
    const [open, setOpen] = useState(props.open);    

    getMemberSession(apis)
    
    const toggleDrawer = () => {
        setOpen(!open);
    };
    
    
    return <Drawer variant="permanent" open={open} sx={{ width: open ? '500px' : null, height: "100vh", overflow: "scroll" }}>
        <Toolbar
            sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'flex-end',
                px: [1],
                background: 'white',
                color: LOGO_TEXT_COLOR,
            }}>
            <IconButton onClick={toggleDrawer} sx={{ background: 'white' }}>
                {!open && <ChevronLeft />}
                {open && <ChevronRight />}
            </IconButton>
            {open && <Typography sx={{ flex: '1 1 10%', overflow: "hidden" }} title={title} variant="h6" component="div" >{title}</Typography>}
            {open && <IconButton onClick={() => { window.close() }} title="Exit the chapter" sx={{ background: 'white' }}><ExitToApp /></IconButton>}
        </Toolbar>            
        {open && page.member_page && page.member_page.status == 1 && <Box>
            <Paper sx={{ m: 1,p:2 }}>
                <Stack direction="row">
                    <FormControlLabel labelPlacement="start" sx={{ ml: 0, mr: 4 }} disabled value={page.member_page ? page.member_page.points : 0} control={<Rating sx={{ mt: -0.6, ml: 1 }} precision={1} />} label='Points' title="Points" />
                    <FormControlLabel
                        control={
                            <Switch checked={page.member_page && page.member_page.pass == 1 ? true : false} disabled/>
                        } label="Pass" />
                </Stack>
            </Paper>
            <Paper sx={{ m: 1,p:2 }}>
                {/* <Comments isTeacher={false} onPosted={(v) => {}} comments={page.member_page ? page.member_page.comment : []} pageid={page.member_page.id} apis={apis} errorNotify={errorNotify} /> */}
                <Messages isTeacher={false} onPosted={(v) => {}} comments={page.messages} pageid={page.member_page.id} apis={apis} errorNotify={errorNotify} />
            </Paper>
        </Box>}
    </Drawer>
}

export default PageSide