import { Alert, Box, List, ListItem, Stack, } from "@mui/material"
import React, { useEffect, useRef, useState } from "react"
import {useParams, useNavigate } from "react-router"
import apis from "../../api";
import { apiResult, getMemberSession } from "../../Utils/Common";

import LessonSideMenu from "../fragement/LessonSideMenu";
import SlideContentItem from "../fragement/SlideContentItem";

export default function Lesson() {    
    const params = useParams()
    const [contentIdx, setContentIdx] = useState(0)
    const [index, setIndex] = useState(0)
    const [error,setError] = useState()
    const [lesson, setLesson] = useState()
    const [contents, setContents] = useState()    
    const [schedule, setSchedule] = useState()    
    const navigate = useNavigate()
    if (!getMemberSession(apis)) {
        navigate('/member/signin')
    }

    const initPage = useRef(false)
    useEffect(() => {        
        if(initPage.current) {
           return 
        }
        initPage.current = true
        
    }, [])
    return <Stack direction="row">
        
            {error && <Alert onClose={()=>navigate(-1)}>{error}</Alert>}
                   
    </Stack>
}