
import { CheckBox, CheckBoxOutlineBlank, ChevronLeft, ChevronRight, ExitToApp, Message, } from "@mui/icons-material"
import { Alert, Avatar, Box, Paper, Divider, FormControlLabel, IconButton, List, ListItem, ListItemAvatar, ListItemText, ListSubheader, Rating, Stack, Switch, TextField, Toolbar, Typography, Badge } from "@mui/material"
import { useState } from "react"

import apis from "../../../api"
import { LOGO_ICON_COLOR, LOGO_TEXT_COLOR } from "../../../app/config"
import { Drawer } from "../../../Component/MuiEx"
import { apiResult, getTeacherSession } from "../../../Utils/Common"
import Comments from "../../fragement/Comments"
import Messages from "../../fragement/Messages"

const ClassMemberList = (props) => {
    const { page, members, onMemberClick, title, classid, errorNotify, hint } = props
    const [selected, setSelected] = useState(-1)
    const [memberList, setMemberList] = useState(members)
    const [error, setError] = useState()
    const [open, setOpen] = useState(props.open);
    const [comments, setComments] = useState()

    getTeacherSession(apis)
    const handleMemberClick = (idx) => {
        if (idx != selected) {
            setSelected(idx)
            onMemberClick && onMemberClick(idx)
            // let mm = [...memberList]
            // mm[idx].messages = []
            // setMemberList(mm)
        }else {
            setSelected(-1)
            onMemberClick && onMemberClick(-1)
        }
    }
    //const navigate = useNavigate()
    const toggleDrawer = () => {
        setOpen(!open);
    };
    const handleCommentChange = (name, value) => {
        if (name == "points") {
            if (memberList[selected].member_page.points == value) {
                value = 0
            }
            apis.teacherPostPage({ id: memberList[selected].member_page.id, [name]: value }).then(ret => {
                apiResult(ret, data => {
                    let mm = [...memberList]
                    mm[selected].member_page.points = value
                    setMemberList(mm)
                    hint("Change point to " + value)
                }, errorNotify ? errorNotify : setError)
            })
        } else if (name == "comment") {
            setComments(value)
        }
    }
    const handlePostedComment = (v) => {
        let mm = [...memberList]
        // mm[selected].member_page.comment = v
        mm[selected].messages.unshift(v)
        setMemberList(mm)
        hint("Post successfully")
    }
    const handleChangeStatus = (idx,f,t)=>{
        let mm = [...memberList]       
        
        // mm[selected].member_page.comment = v
        if(f == 0 && t == 1) {
            mm[selected].messages[idx].status = t
            mm[selected].newmsgs--;
            setMemberList(mm)            
        }
    }
    const handleAssign = (idx, status) => {
        if (!memberList[idx].member_page) {
            apis.teacherAssignPage({ classid, courseid: 0, lessonid: page.lesson_id, pageid: page.id, memberid: memberList[idx].member_id }).then(ret => {
                apiResult(ret, data => {
                    let mm = [...memberList]
                    data.content = []
                    data.comment = []
                    mm[idx].member_page = data
                    setMemberList(mm)
                    hint("Change the assignment status of " + memberList[idx].member.name + " to " + ["'No Assignment'", "'Assignment'"][status])
                }, errorNotify ? errorNotify : setError)
            })
        } else {
            apis.teacherReAssignPage({ id: memberList[idx].member_page.id, status }).then(ret => {
                apiResult(ret, data => {
                    let mm = [...memberList]
                    mm[idx].member_page.status = status
                    setMemberList(mm)
                    hint("Change the assignment status of " + memberList[idx].member.name + " to " + ["'No Assignment'", "'Assignment'"][status])
                }, errorNotify ? errorNotify : setError)
            })
        }
    }
    const handlePass = (idx, pass) => {

        if (memberList[idx].member_page && pass != memberList[idx].member_page.pass) {
            apis.teacherPassPage({ id: memberList[idx].member_page.id, pass }).then(ret => {
                apiResult(ret, data => {
                    let mm = [...memberList]
                    mm[idx].member_page.pass = pass
                    setMemberList(mm)
                    hint("Change the pass status of " + memberList[idx].member.name + " to " + ["'No Pass'", "'Pass'"][pass])
                }, errorNotify ? errorNotify : setError)
            })
        }
    }
    return <Drawer variant="permanent" open={open} sx={{ width: open ? '500px' : null, height: "100vh", overflow: "scroll" }}>
        <Toolbar
            sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'flex-end',
                px: [1],
                background: 'white',
                color: LOGO_TEXT_COLOR,
            }}>
            <IconButton onClick={toggleDrawer} sx={{ background: 'white' }}>
                {!open && <ChevronLeft />}
                {open && <ChevronRight />}
            </IconButton>
            {open && <Typography sx={{ flex: '1 1 10%', overflow: "hidden" }} title={title} variant="h6" component="div" >{title}</Typography>}
            {open && <IconButton onClick={() => { window.close() }} title="Exit the chapter" sx={{ background: 'white' }}><ExitToApp /></IconButton>}
        </Toolbar>
        {error && <Alert severity="error" onClose={() => setError()}>{error}</Alert>}
        <Paper sx={{ m: 1 }}>
            <List component="nav" sx={{ p: 0 }} subheader={open && <ListSubheader>Students</ListSubheader>}>
                {memberList && memberList.map((item, idx) => {                    
                    return <ListItem selected={idx === selected} key={idx} onClick={() => { handleMemberClick && handleMemberClick(idx) }}
                        sx={{ pt: 1, pb: 1, cursor: 'pointer' }}
                        secondaryAction={
                            open && <IconButton edge="end" title="Assignment" onClick={() => { handleAssign(idx, item.member_page && item.member_page.status == 1 ? 0 : 1) }}>
                                {item.member_page && item.member_page.status == 1 ? <CheckBox /> : <CheckBoxOutlineBlank />}
                            </IconButton>}>
                        <ListItemAvatar sx={{ display: open ? 'none' : 'block' }} title={item.member.name}>
                            <Badge color="error" badgeContent={item.newmsgs}>
                                <Avatar sx={{ bgcolor: idx === selected ? LOGO_ICON_COLOR : LOGO_TEXT_COLOR }}>
                                    {item.member.name.substring(0, 2)}
                                </Avatar>
                            </Badge>
                        </ListItemAvatar>
                        <ListItemText sx={{ color: LOGO_TEXT_COLOR, fontSize: "16px", fontWeight: "bold" }}>
                            {item.newmsgs > 0  ? <Badge color="error" badgeContent={item.newmsgs}>{item.member.name}</Badge>:item.member.name}
                    </ListItemText>
                    </ListItem>
                }
                )}
        </List>
    </Paper>
    {
        open && selected >= 0 && memberList[selected].member_page && memberList[selected].member_page.status == 1 && <Box>
            <Paper sx={{ m: 1, p: 2 }}>
                <Stack direction="row">
                    <FormControlLabel labelPlacement="start" sx={{ ml: 0, mr: 4 }} value={memberList[selected].member_page ? memberList[selected].member_page.points : 0} control={<Rating onChange={(e) => handleCommentChange('points', e.target.value)} sx={{ mt: -0.6, ml: 1 }} precision={1} />} label='Points' title="Points" />
                    <FormControlLabel
                        control={
                            <Switch checked={memberList[selected].member_page && memberList[selected].member_page.pass == 1 ? true : false} onChange={(e) => handlePass(selected, e.target.checked ? 1 : 0)} />
                        } label="Pass" />
                </Stack>
            </Paper>
            <Paper sx={{ m: 1, p: 2 }}>
                {/* <Comments isTeacher={true} onPosted={(v) => handlePostedComment(v)} comments={memberList[selected].member_page ? memberList[selected].member_page.comment : []} pageid={memberList[selected].member_page.id} apis={apis} errorNotify={errorNotify} /> */}
                <Messages isTeacher={true} onChangeStatus={handleChangeStatus} onPosted={(v) => handlePostedComment(v)} comments={memberList[selected].messages} pageid={memberList[selected].member_page.id} apis={apis} errorNotify={errorNotify} />
            </Paper>
        </Box>
    }
    </Drawer >
}

export default ClassMemberList