
import { Box, Avatar, Typography, Stack, Button, IconButton, Snackbar, Alert, Grid, CardHeader, Card, CardContent, CardActions, Paper, Badge, Chip } from "@mui/material"
import React, { useEffect, useState } from "react"
import apis from "../../api"

import { Weeks, apiResult, getLocaleDate, getMemberSession, minuteToTime, orderList, } from "../../Utils/Common"
import { useNavigate, useParams } from "react-router"
import { ArrowLeft, ArrowRight, CalendarMonth, CheckBoxOutlineBlank, EventAvailable, Grade, Message, PlaylistAdd, PlaylistAddCheck, RotateRight, Toc } from "@mui/icons-material"
import { LOGO_ICON_COLOR, } from "../../app/config"

const ClassHome = () => {
    const [class1, setClass1] = useState()
    const [error, setError] = useState()
    const [hint, setHint] = useState()
    const navigate = useNavigate()
    const [currentScheduleIdx, setCurrentScheduleIdx] = useState(0)
    const [selectedSchedule, setSelectedSchedule] = useState(0)

    const session = getMemberSession(apis)
    if (!session) {
        navigate('/member/signin')
    }
    const params = useParams()
    let initPage = false
    useEffect(() => {
        if (!initPage) {
            initPage = true
            apis.memberClass(params.id).then(ret => {
                apiResult(ret, data => {
                    data.class_schedules = orderList(data.class_schedules, 'sdate', 0)                    
                    data.course.lessons = orderList(data.course.lessons, 'lessonNo', 0)
                    
                    for (let l in data.course.lessons) {
                        data.course.lessons[l].lesson_pages = orderList(data.course.lessons[l].lesson_pages, 'pageNo', 0)
                        let cc = 0
                        for(let pp in data.course.lessons[l].lesson_pages) {
                            let page = data.course.lessons[l].lesson_pages[pp]
                            let mp = getMemberPage(data,page.id)
                            page.memberpage = mp
                            if(mp !== false) {
                                cc++
                            }
                        }
                        if(cc == 0) {
                            data.course.lessons[l].assigned = false
                        }else {
                            data.course.lessons[l].assigned = true
                        }
                    }
                    data.mypass = 0
                    data.mypoints = 0
                    for (let i in data.member_pages) {
                        if (data.member_pages[i].pass == 1) {
                            data.mypass++
                            data.mypoints+=data.member_pages[i].points
                        }
                    }                    
                    setClass1(data)
                    let cs = getCurrentSchedule(data.class_schedules)
                    setCurrentScheduleIdx(cs)
                    setSelectedSchedule(cs)
                    
                }, setError)
            })
        }
    }, [])
    const countPages = (cls) => {
        let pages = 0
        if (cls && cls.course) {
            for (var l in cls.course.lessons) {
                pages += cls.course.lessons[l].lesson_pages.length
            }
        }
        return pages
    }
    const getMemberPage = (class1,id) => {
        for (var idx in class1.member_pages) {
            let page = class1.member_pages[idx]
            if (page.page_id == id) {
                return page
            }
        }
        return false
    }
    const handlePage = (cid, pid) => {
        window.open("/member/class/lesson/" + cid + "/" + pid)
    }
    const getCurrentSchedule = (schedules) => {
        let today = getLocaleDate()
        for (var idx in schedules) {
            let s = schedules[idx]
            if (s.sdate >= today) {
                return idx
            }
        }
        return schedules.length - 1
    }
    const handleSelectedSchedule = (idx) => {
        setSelectedSchedule(idx)
    }
    return <Box>
        <Paper sx={{ p: 2, m: { xs: 0, sm: 2 } }}>
            <Stack direction={"row"}>
                <Stack direction={"column"} sx={{ border: "0px solid #eee", p: 1, width: "100%" }}>
                    <Typography sx={{ mb: 0.5, fontWeight: 'bold', fontSize: '24px', color: LOGO_ICON_COLOR }}>{class1?.name}</Typography>
                    {class1 && <Stack direction={"row"}>
                        <Button size="small" variant="outlined" title={"total chapters"} sx={{ p: "0px !important", mr: 1 }} color={"warning"} startIcon={<Toc fontSize="small" />}>{countPages(class1)}</Button>
                        <Button size="small" variant="outlined" title={"assigned chapters"} sx={{ p: "0px !important", mr: 1 }} color={"primary"} startIcon={<PlaylistAdd fontSize="small" />}>{class1.member_pages.length}</Button>
                        <Button size="small" variant="outlined" title={"finished chapters"} sx={{ p: 0 ,mr:1}} color={"success"} startIcon={<PlaylistAddCheck fontSize="small" />}>{class1.mypass}</Button>
                        <Button size="small" variant="outlined" title={"points"} sx={{ p: 0 }} color={"success"} startIcon={<Grade fontSize="small" />}>{class1.mypoints}</Button>
                    </Stack>}
                </Stack>
                <Box sx={{ pr: 2 }}>
                    <Stack direction={"row"} sx={{ display: { xs: "none", sm: "none", md: "flex" } }}>
                        <IconButton onClick={() => { setCurrentScheduleIdx(currentScheduleIdx > 0 ? currentScheduleIdx - 1 : 0) }}><ArrowLeft /></IconButton>
                        {class1 && class1.class_schedules.map((schedule, idx) => {
                            if (idx >= currentScheduleIdx && idx < Number(currentScheduleIdx) + 5) {
                                let bgcolor = "inherit"
                                if (schedule.sdate == getLocaleDate()) {
                                    bgcolor = "success"
                                }
                                return <Stack direction={"column"} key={idx} sx={{ m: 1, p: 1, border: "1px solid #ddd", cursor: "pointer", bgcolor: selectedSchedule == idx ? "#eee" : "#fff" }}
                                    onClick={() => { handleSelectedSchedule(idx) }}>
                                    <Button variant={bgcolor == "success" ? "contained" : "text"} sx={{ p: 0, m: 0.5 }} color={bgcolor}>{schedule.sdate}</Button>
                                    <Typography sx={{ fontSize: "12px" }}>{Weeks[schedule.wod]} {minuteToTime(schedule.begintime)}-{minuteToTime(schedule.begintime + schedule.duration)}</Typography>
                                </Stack>
                            }
                        })}
                        <IconButton onClick={() => { setCurrentScheduleIdx(currentScheduleIdx < class1.class_schedules.length - 1 ? (Number(currentScheduleIdx) + 1) : (class1.class_schedules.length - 1)) }}><ArrowRight /></IconButton>
                    </Stack>
                    <Stack direction={"row"} sx={{ display: { md: "none", sm: "flex", xs: 'none', lg: 'none' } }}>
                        <IconButton onClick={() => { setCurrentScheduleIdx(currentScheduleIdx > 0 ? currentScheduleIdx - 1 : 0) }}><ArrowLeft /></IconButton>
                        {class1 && class1.class_schedules.map((schedule, idx) => {
                            if (idx >= currentScheduleIdx && idx < Number(currentScheduleIdx) + 3) {
                                let bgcolor = "inherit"
                                if (schedule.sdate == getLocaleDate()) {
                                    bgcolor = "success"
                                }
                                return <Stack direction={"column"} key={idx} sx={{ m: 1, p: 1, border: "1px solid #ddd", cursor: "pointer", bgcolor: selectedSchedule == idx ? "#eee" : "#fff" }}
                                    onClick={() => { handleSelectedSchedule(idx) }}>
                                    <Button variant={bgcolor == "success" ? "contained" : "text"} sx={{ p: 0, m: 0.5 }} color={bgcolor}>{schedule.sdate}</Button>
                                    <Typography sx={{ fontSize: "12px" }}>{Weeks[schedule.wod]} {minuteToTime(schedule.begintime)}-{minuteToTime(schedule.begintime + schedule.duration)}</Typography>
                                </Stack>
                            }
                        })}
                        <IconButton onClick={() => { setCurrentScheduleIdx(currentScheduleIdx < class1.class_schedules.length - 1 ? (Number(currentScheduleIdx) + 1) : (class1.class_schedules.length - 1)) }}><ArrowRight /></IconButton>
                    </Stack>

                </Box>

            </Stack>
            <Stack direction={"row"} sx={{ display: { md: "none", sm: "none", xs: 'flex', lg: 'none' } }}>
                <IconButton onClick={() => { setCurrentScheduleIdx(currentScheduleIdx > 0 ? currentScheduleIdx - 1 : 0) }}><ArrowLeft /></IconButton>
                {class1 && class1.class_schedules.map((schedule, idx) => {
                    if (idx >= currentScheduleIdx && idx < Number(currentScheduleIdx) + 2) {
                        let bgcolor = "inherit"
                        if (schedule.sdate == getLocaleDate()) {
                            bgcolor = "success"
                        }
                        return <Stack direction={"column"} key={idx} sx={{ m: 1, p: 1, border: "1px solid #ddd", cursor: "pointer", bgcolor: selectedSchedule == idx ? "#eee" : "#fff" }}
                            onClick={() => { handleSelectedSchedule(idx) }}>
                            <Button variant={bgcolor == "success" ? "contained" : "text"} sx={{ p: 0, m: 0.5 }} color={bgcolor}>{schedule.sdate}</Button>
                            <Typography sx={{ fontSize: "12px" }}>{Weeks[schedule.wod]} {minuteToTime(schedule.begintime)}-{minuteToTime(schedule.begintime + schedule.duration)}</Typography>
                        </Stack>
                    }
                })}
                <IconButton onClick={() => { setCurrentScheduleIdx(currentScheduleIdx < class1.class_schedules.length - 1 ? (Number(currentScheduleIdx) + 1) : (class1.class_schedules.length - 1)) }}><ArrowRight /></IconButton>
            </Stack>
        </Paper>


        {class1?.course?.lessons?.map((lesson, idx) => {
            {/* console.log(class1?.course?.lessons) */}
            return lesson.assigned && <Box key={idx} sx={{ m: 2 }}>
                <Typography variant="h6">Section {lesson.lessonNo}. {lesson.name}</Typography>
                <Box sx={{ border: "1px solid #ddd6", bgcolor: "#eee6", p: 2,mt:1 }}><Typography variant="body2">{decodeURIComponent(lesson.description)}</Typography></Box>
                <Grid container spacing={2}>
                    {lesson.lesson_pages.map((page, pidx) => {
                        //console.log([page.title,page.id,class1.member_pages])
                        //let mp = getMemberPage(page.id) 
                        let mp = page.memberpage  
                        {/* console.log([page.id,mp])                      */}
                        page.tags = page.tags&&(typeof page.tags == "string") ?page.tags.split(","):page.tags
                        return mp && <Grid item xs={12} sm={6} md={4} lg={2.4} sx={{ mt: 2 }} key={pidx}><Card key={pidx}>
                            <CardHeader avatar={<Avatar sx={{ bgcolor: (mp.pass == 1 ? "green" : "#ddd") }} aria-label="recipe">{page.pageNo}</Avatar>}
                                title={page.title}
                                sx={{ cursor: 'pointer' }}
                                subheader={<Stack direction={"row"}>
                                    {page.tags && page.tags.map((t,ii)=>{
                                        return <Chip size="small" variant="default" key={ii} label={"#"+t} />
                                    })}</Stack>
                                }
                                onClick={() => handlePage(class1.id, page.id)}
                                action={
                                    <IconButton aria-label="Enter" onClick={() => handlePage(class1.id, page.id)} >
                                        {page.messages.length > 0 ? <Badge size="small" badgeContent={page.messages.length} color="error"><Message /></Badge> : <ArrowRight />}
                                    </IconButton>
                                }
                            />
                            <CardContent>
                                <Typography variant="body2" color="text.secondary">
                                    {page.subtitle}
                                </Typography>
                            </CardContent>
                            <CardActions disableSpacing>
                                <Button size="small" variant="text" title={"assigned date"} sx={{ p: "0px !important", mr: 1 }} color={"inherit"} startIcon={<CalendarMonth fontSize="small" />}>{mp.sdate}</Button>
                                <Button onClick={() => handlePage(class1.id, page.id)} size="small" variant="text" title={"finished state"} sx={{ p: 0, marginLeft: "auto" }} color={mp.pass == 1 ? "success" : "primary"} startIcon={mp.pass == 0 ? <RotateRight fontSize="small" /> : <PlaylistAddCheck fontSize="small" />}>{mp.pass == 1 ? "Finished" : "In-Progress"}</Button>
                            </CardActions>
                        </Card></Grid>
                    })}
                </Grid>
            </Box>
        })}
        <Snackbar
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
            open={error || hint ? true : false}
            onClose={() => { if (hint) { setError(); setHint() } }}
            autoHideDuration={3000}>
            <Alert onClose={() => { setError(); setHint() }} severity={error ? "error" : "success"} sx={{ width: '100%' }}>
                {error || hint}
            </Alert>
        </Snackbar>
    </Box >
}
export default ClassHome