import axios from 'axios'
import { hex_md5 } from 'react-native-md5'
const api = axios.create({
    baseURL: '/memberapi',
    //baseURL: 'http://localhost/sales',
})
const signIn = (userInfo, headers) => api.post(`/user/login`, userInfo, headers)
const signOut = (headers)=>api.get(`/user/signout`,headers)
const signUp = (userInfo, headers) => api.post(`/user/register`, userInfo, headers)
const sendCode = (data,headers) =>api.post(`/user/sendcode`,data,headers)
const sendCode1 = (data,headers) =>api.post(`/user/sendcode1`,data,headers)
const resetPwd = (data,headers) => api.post(`/user/resetpwd`,data,headers)
const emailVerify = (data,headers) => api.post(`/user/verifycode`,data,headers)
const loadUseProfile = (headers)=>api.get(`/user/profile`,headers)
const modifyUserProfile = (data,headers)=>api.post(`/user/profile`,data,headers)
const setHeader = (k, v) => {
    api.defaults.headers.post[k] = v
    api.defaults.headers.get[k] = v
    api.defaults.headers.put[k] = v
    api.defaults.headers.delete[k] = v
}

const apis = {
    signIn,
    signOut,
    signUp,
    resetPwd,
    sendCode,sendCode1,
    emailVerify,
    loadUseProfile,
    setHeader,
    modifyUserProfile,  
    getEvent:(code,applyid,token,time,headers)=>api.get( `/event/getevent?code=`+code+(applyid>0?(`&applyid=`+applyid+`&token=`+token+`&time=`+time):''),headers),      
    applyEvent:(data,headers)=>api.post(`/event/applyevent`,data,headers),
    payEvent:(data,headers)=>api.post(`/event/payevent`,data,headers),
    loadEvents:(headers)=>api.get('/event/loadevents',headers),
    loadApplicants:(code,headers)=>api.get('/event/loadapplicants?code='+code,headers),
    //home
    loadResources:(type,resourcetype,page,pagesize,countdata,headers) => api.get(`/home/loadresources?type=`+type+`&resource_type=`+resourcetype+`&page=`+page+'&pagesize='+pagesize+'&countdata='+countdata+`&time=`+Date.now(),headers),
    //pay
    // setupPay:(id,code,headers)=>api.post('/event/setuppay',{id:id,code:code},headers),
    //lesson
    // lessonGet:(courseid,lesson,headers)=>api.get('/lesson/lesson?courseid='+courseid + '&lessonno='+lesson,headers),
    teacherSignIn:(data,headers)=>api.post('/teacher/signin',data,headers),
    // teacherClasses:(headers)=>api.get('/teacher/classes',headers),
    // teacherClass:(id,headers)=>api.get('/teacher/class?id='+id,headers),
    teacherSchedule:(y,m,headers)=>{
        return y>0 && m>0 ?api.get('/teacher/schedules?year='+y+"&month="+m,headers):api.get('/teacher/schedules',headers)
    },
    lessonMembers:(scheduleid,headers)=>api.get('/teacher/lessonmembers?scheduleid='+scheduleid,headers),
    teacherClasses:(headers)=>api.get('/teacher/classes',headers),
    teacherClass:(id,headers)=>api.get('/teacher/class?id='+id,headers),
    teacherAssignPage:(data,headers)=>api.post("/teacher/memberpage",data,headers),
    teacherReAssignPage:(data,headers)=>api.put("/teacher/memberpage",data,headers),
    teacherPassPage:(data,headers)=>api.put("/teacher/memberpage",data,headers),
    teacherPostPage:(data,headers)=>api.put("/teacher/memberpage",data,headers),
    teacherPostMessage:(data,headers)=>api.post('/teacher/message',data,headers),
    teacherPutMessage:(data,headers)=>api.put('/teacher/message',data,headers),
    teacherClassPage:(cid,pid,headers)=>api.get('/teacher/classpage?classid='+cid+'&pageid='+pid,headers),
    teacherHomework:(scheduleid,member_id,headers)=>api.get('/teacher/homework?scheduleid='+scheduleid+'&member_id='+member_id,headers),
    teacherHomeworkPost:(data,headers)=>api.post('/teacher/homework',data,headers),
    teacherScheduleInfo:(scheduleid,headers)=>api.get('/teacher/schedule?id='+scheduleid,headers),
    teacherLesson:(scheduleid,headers)=>api.get('/teacher/lesson?scheduleid='+scheduleid,headers),
    teacherCheckin:(scheduleid,headers)=>api.get('/teacher/checkin?scheduleid='+scheduleid,headers),
    teacherLessonRecordPost:(data,headers)=>api.post('/teacher/lessonrecord',data,headers),
    teacherLessonRecord:(schedule_id,member_id,headers)=>api.get('/teacher/lessonrecord?schedule_id='+schedule_id+'&member_id='+member_id,headers),
    teacherPoints:(data,headers)=>api.post('/teacher/points',data,headers),
    teacherUsePoints:(data,headers)=>api.post('/teacher/usepoints',data,headers),
    // teacherLessonSyncGet:(scheduleid,token,time,headers)=>api.get('/teacher/lessonsync?token='+ token+'&time='+time+'&scheduleid='+scheduleid,headers),
    // teacherLessonSyncPut:(scheduleid,token,time,page,section,data,headers)=>api.put('/teacher/lessonsync',{scheduleid,token,time,page,section,data},headers),
    memberSignIn:(data,headers)=>api.post('/member/signin',data,headers),
    memberSchedule:(y,m,headers)=>{
        return y>0 && m>0 ?api.get('/member/schedules?year='+y+"&month="+m,headers):api.get('/teacher/schedules',headers)
    },
    memberPostMessage:(data,headers)=>api.post('/member/message',data,headers),
    memberPutMessage:(data,headers)=>api.put('/member/message',data,headers),
    memberClasses:(headers)=>api.get('/member/classes',headers),
    memberClass:(id,headers)=>api.get('/member/class?id='+id,headers),
    memberClassPage:(cid,pid,headers)=>api.get('/member/classpage?classid='+cid+'&pageid='+pid,headers),
    memberPostPage:(data,headers)=>api.put("/member/memberpage",data,headers),
    memberLesson:(scheduleid,headers)=>api.get('/member/lesson?scheduleid='+scheduleid ,headers),
    memberHomework:(scheduleid,headers)=>api.get('/member/homework?scheduleid='+scheduleid,headers),
    memberHomeworkPost:(data,headers)=>api.post('/member/homework',data,headers),

    // memberCheckin:(scheduleid,headers)=>api.get('/member/checkin?scheduleid='+scheduleid,headers),
    // memberLessonSync:(scheduleid,token,time,headers)=>api.get('/member/lessonsync?token='+ token+'&time='+time+'&scheduleid='+scheduleid,headers),
    // memberLessonSyncPut:(scheduleid,token,time,page,section,data,headers)=>api.put('/member/lessonsync',{scheduleid,token,time,page,section,data},headers),
    // studentLessons:(headers)=>api.get('/lesson/student',headers),
    pageBoard:(pageid,headers)=>api.get('/lesson/board?pageid='+pageid,headers),
    pagePost:(data,headers)=>api.post('/lesson/board',data,headers),
    holidayGet:(year,month,headers)=>api.get('/resource/holiday?year='+year+(month?('&month='+month):''),headers),
    uploadResource:(session,whoupload)=>{
        let time = Date.now()
        let headers = {
            //'request-userid':session.userid,
            'request-appid':0,
            'request-token':hex_md5(session.token+time),
            'request-time':time
        }
        if(whoupload === 'teacher') {
            headers['request-teacherid'] = session.userid
        }else if (whoupload === 'member') {
            headers['request-memberid'] = session.userid
        }else {
            headers['request-userid'] = session.userid
        }
        return {
            url:'/memberapi/resource/upload',
            headers:headers
        }},
}

export default apis
