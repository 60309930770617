import { Check, Clear, Pending } from "@mui/icons-material"
import { Box, FormControlLabel, Alert, Stack, Switch, TextField, Typography } from "@mui/material"
import { useEffect, useState } from "react"
import { SingleSelector } from "../../Component/MuiEx"

const ItemContext = (props) => {
    const { content, onChange, viewer,id } = props    
    //const [_content,setContent] = useState(content)
    const [isRight, setIsRight] = useState()
    const [score, setScore] = useState()
    const [teacher, setTeacher] = useState()
    const [row,setRow] = useState(viewer === 'teacher' ? 1:3)
    useEffect(() => {        
        setIsRight(content?.isRight)
        setScore(content?.score)
        setTeacher(content?.teacher)
    }, [id,content])
    const handleChange = (name, value) => {
        if(name === 'teacher') {
            setTeacher(value)
        }else if(name === 'isRight') {
            setIsRight(value)
        }else if(name === 'score') {
            setScore(value)
        }
        onChange && onChange(name, value)
    }
    return <Box sx={props.sx}>
        <Stack direction={'row'} sx={{ ml: 2 }}>            
            {(viewer === 'teacher' || isRight !== undefined) && (viewer === 'teacher' ? <SingleSelector sx={{mr:2}} items={["Right", "Wrong"]} values={[true, false]} defaultValue={isRight} onChange={(name, value) => handleChange('isRight', value)} /> : (isRight === true ? <Check sx={{mr:2,mt:1,color:'green'}}/> : (isRight === false ? <Clear  sx={{mr:2,mt:1,color:'red'}}/> : <Pending sx={{mr:2,mt:1,color:'gray'}}/>)))}            
            {isRight && (viewer === 'teacher' || score >= 0) && <SingleSelector items={[0, 1, 2, 3, 4, 5]} defaultValue={score} disabled={viewer !== 'teacher'} onChange={(name, value) => handleChange('score', value)} />}
            <Typography sx={{ flex: '1 1 10%' }} variant="h6" component="div" > </Typography>            
        </Stack>
        {(viewer === 'teacher' || teacher) && (viewer === 'teacher' ? <TextField placeholder="comments" multiline rows={row} onFocus={()=>setRow(6)} onBlur={()=>setRow(1)} value={teacher?teacher:''} disabled={viewer !== 'teacher'} fullWidth onChange={(e) => handleChange('teacher', e.target.value)} />:(teacher && <Alert sx={{p:0,pl:2,pr:2}} severity={isRight ?'success':"warning"} icon={false}><pre>{teacher}</pre></Alert>))} 
    </Box>
}

export default ItemContext