import {  Toolbar, Typography,    IconButton, Button,  } from "@mui/material"
import {  AutoFixHigh,   Slideshow} from '@mui/icons-material';
import { useEffect, useRef, useState } from "react";
import { SingleSelector } from "../../../Component/MuiEx";
const LessonBar = (props) => {
    const {onCheckin,begintime,onLessonShow,onCovered,onWidthChange} = props
    const [count,setCount] = useState(new Date().getHours()*60 + new Date().getMinutes() - Number(begintime))
    const countRef = useRef(count)    
    const timeout = useRef()
    const autoIncrement = ()=>{
        countRef.current = new Date().getHours()*60 + new Date().getMinutes() - Number(begintime)
        setCount(countRef.current)
        if(timeout.current) window.clearTimeout(timeout.current)
        timeout.current = window.setTimeout(autoIncrement,1000)
    }
    useEffect(()=>{
        timeout.current = window.setTimeout(autoIncrement,1000)
    },[])
    
    return <Toolbar sx={{...props.sx}} style={{ paddingLeft: 2,marginLeft:-16,minHeight:'32px',background:'#ccc6'}}>                                            
            <Typography sx={{ flex: '1 1 10%' }} variant="h6" component="div" > </Typography> 
            {onCheckin && <Button  size="small" title={"Check in"} onClick={onCheckin} variant="contained" color="success" sx={{mr:2}}>Start lesson</Button>}
            {onLessonShow && <IconButton size="small" onClick={onLessonShow}><Slideshow size="small"/></IconButton>}
            {onCovered && <IconButton size="small" onClick={onCovered}><AutoFixHigh size="small"/></IconButton>}
            {onWidthChange && <SingleSelector items={['Full','Large','Middle']} values={['100%','lg','md']} onChange={(n,v)=>onWidthChange(v)} />}
            
    </Toolbar>
}
export default LessonBar