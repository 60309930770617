import { Menu } from "@mui/icons-material";
import { MenuItem, InputLabel, Select, Box, Grid, CardHeader, CardContent, Card, FormControl, ListItemIcon, ListItemText, Alert, Paper, Toolbar, Typography, Stack, Snackbar, Backdrop, IconButton, Chip, Switch, FormControlLabel, Divider, Button, Avatar } from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate, } from "react-router";
import apis from "../../api"
import { LOGO_TEXT_COLOR } from "../../app/config";
import { apiResult, getDatesInRange, getHoliday, getLocaleDate, getMemberSession, holidayColors, } from "../../Utils/Common"

const Schedule = () => {
    const [loading, setLoading] = useState(false)
    const [hintMsg, setHintMsg] = useState()
    const [schedules, setSchedules] = useState([])
    const [dates, setDates] = useState([])
    const [field, setField] = useState({ year: new Date().getFullYear(), month: new Date().getMonth() + 1 })
    const [error, setError] = useState()
    const [holidays,setHolidays] = useState([])  

    const navigate = useNavigate()
    if (!getMemberSession(apis)) {
        navigate('/member/signin')
    }
    const weeks = ['S', 'M', 'Tu', 'W', 'Th', 'F', 'Sa']

    const handleHintClose = () => {
        setHintMsg()
    }

    const loadSchedule = (field) => {
        setLoading(true)
        apis.memberSchedule(field.year, field.month).then(ret => {
            setLoading(false)
            apiResult(ret, data => {
                setHolidays(data.holidays)
                loadData(field,data.schedules)
            }, setError)
        })
    }
    const loadData = (field, data) => {
        let s = {}
        let nowdate = new Date()
        let year = field.year > 0 ? Number(field.year) : nowdate.getFullYear()
        let month = field.month > 0 ? Number(field.month) : nowdate.getMonth() + 1
        let bdate = year + '-' + (month < 10 ? ('0' + month) : month) + '-01 08:00:00'
        let edate = (month == 12 ? (year + 1) : year) + '-' + (month == 12 ? '01' : (month < 9 ? ('0' + (month + 1)) : (month + 1))) + '-01'
        let dates = getDatesInRange(bdate, edate)
        let d = new Date(bdate).getDay()
        for (let i = 0; i < d; i++) {
            dates.unshift('0')
        }
        setDates(dates)
        data.map(d => {
            let dd = { ...d }
            if (s[dd.sdate]) {
                s[dd.sdate].push(dd)
            } else {
                s[dd.sdate] = [dd]
            }
        })
        setSchedules(s)
    }
    let initPage = false
    useEffect(() => {
        if (initPage) return
        initPage = true
        loadSchedule(field)
    }, [])

    const handleChange = (key, value) => {
        let tt = { ...field, [key]: value }
        setField(tt)
        loadSchedule(tt)
    }
    const handleClass = (lesson) => {
        window.open('/member/lesson/' + lesson.id,"lessonWindow")
    }
    const handleInClass = (lesson) => {
        window.open('/member/inlesson/' + lesson.id,"lessonWindow")
    }
    const curYear = new Date().getFullYear()
    const curMonth = new Date().getMonth() + 1
    return (
        <Box sx={{ p: 1 }}>
            <Backdrop open={loading} />
            {error && <Alert severity={"warning"} sx={{ width: '100%', marginTop: "5px", mb: 2 }} onClose={() => setError()}>{error}</Alert>}
            <Paper sx={{ marginBottom: 2, p: 1 }}>
                <Toolbar style={{ paddingLeft: 2 }}>
                    <FormControl fullWidth sx={{ mr: 2 }}>
                        <InputLabel id="demo-simple-select-autowidth-label1">Select year</InputLabel>
                        <Select labelId="demo-simple-select-autowidth-label1"
                            onChange={(e) => { handleChange('year', e.target.value) }}
                            defaultValue={curYear}
                            label="Select year" >
                            {[curYear - 1, curYear, curYear + 1, curYear + 2].map((p, idx) => {
                                return <MenuItem key={idx} value={p}>{p}</MenuItem>
                            })}
                        </Select>
                    </FormControl>
                    <FormControl fullWidth sx={{ mr: 2 }}>
                        <InputLabel id="demo-simple-select-autowidth-label1">Select month</InputLabel>
                        <Select labelId="demo-simple-select-autowidth-label1"
                            onChange={(e) => { handleChange('month', e.target.value) }}
                            defaultValue={curMonth}
                            label="Select month" >
                            {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map((p, idx) => {
                                return <MenuItem key={idx} value={p}>{p}</MenuItem>
                            })}
                        </Select>
                    </FormControl>
                    <Typography sx={{ flex: '1 1 100%' }} variant="h6" component="div" ></Typography>
                    <IconButton ><Menu /> </IconButton>
                </Toolbar>
            </Paper>
            {/* {schedules.length == 0 && <Alert severity={"info"}>Ops! There is not any data</Alert>} */}
            <Box>
                <Grid container spacing={1}>
                    {dates.map((item, index) => {
                        const data = schedules[item] ? schedules[item] : []
                        const today = getLocaleDate(new Date())
                        const h = getHoliday(holidays,item)
                        //const bgcolor = h?h.color:'#fff'
                        const bgcolor = h ? holidayColors[h.type]:'#fff'    
                        //console.log([item,new Date(item),new Date(item).getDay(),weeks]) 
                        return <Grid key={index} item xs={12} sm={4} md={12 / 7} >
                            {item == '0' ? <></> : <Card key={index}>
                                <CardHeader sx={{ padding: '5px !important', bgcolor: today == item?'#059':'#059a', color: '#fff' }} title={item}
                                    avatar={<Avatar sx={{ bgcolor: today==item?'#ff5722':'#fff', color: today == item?'#fff':'#ff5722', width: 25, height: 25,p:0.5}} aria-label="recipe">{weeks[new Date(item + ' 12:00:00').getDay()]}</Avatar>} />
                                <CardContent sx={{ minHeight: 150, padding: '5px !important' }}>
                                    {h && <Typography variant='body2' sx={{color:bgcolor,fontSize:"10px"}}>{h.name}</Typography>}
                                    {data.map((lesson, index1) => {
                                        let t = new Date()
                                        let tt = t.getHours()*60+t.getMinutes()
                                        const isClass = tt>=lesson.begintime && tt<=(lesson.begintime+lesson.duration)
                                        const isHomework = tt>lesson.begintime + lesson.duration                                        
                                        return <Paper key={index1} sx={{ p: 0.5, mb: '4px',bgcolor: lesson.member_id > 0 ? '#f001' : '#0001' }} >
                                            <Stack direction={'row'} title={lesson.class ? lesson.class.name : lesson.member?.name}>
                                                <Typography variant="h6" color="text.secondary" sx={{ fontSize: '10px', color: '#000', fontWeight: 'bold', marginRight: '6px' }}>{Math.floor(lesson.begintime / 60) + ":" + (lesson.begintime % 60 < 10 ? ('0' + lesson.begintime % 60) : (lesson.begintime % 60))}~{Math.floor((lesson.begintime + lesson.duration) / 60) + ":" + ((lesson.begintime + lesson.duration) % 60 < 10 ? ('0' + (lesson.begintime + lesson.duration) % 60) : ((lesson.begintime + lesson.duration) % 60))}</Typography>
                                                <Typography sx={{ flex: '1 1 10%' }}></Typography>
                                                {lesson.teacher_id > 0 && <Chip size={'small'} color={'success'} variant={"outlined"} sx={{ p: 0, fontSize: '10px', height: '18px', lineHeight: '1' }} label={lesson.teacher?.name} />}
                                                {lesson.assistant_id > 0 && <Chip size={'small'} color={'default'} variant={"outlined"} sx={{ p: 0, fontSize: '10px', height: '18px', lineHeight: '1' }} label={lesson.assistant?.name} />}
                                            </Stack>                                            
                                            <Box sx={{bgcolor:"#fefefeaa",p:1,mt:"2px",mb:'4px'}}>
                                            <Typography title={lesson.course?.name} variant="h6" color="text.secondary" sx={{ fontSize: '10px', fontWeight: 'bold', flex: '1 1 60%' }}>{lesson.course?.name}</Typography>
                                            <Stack direction={'row'} title={lesson.class ? lesson.class.name : lesson.member?.name}>
                                                <Typography variant="h6" color="text.secondary" sx={{ fontSize: '10px', fontWeight: 'bold', marginRight: '6px' }}>[Lesson {lesson.lesson}]{lesson.course_lesson?.name}</Typography>
                                                <Typography title={lesson.course?.name} variant="h6" color="text.secondary" sx={{ fontSize: '10px', fontWeight: 'bold', flex: '1 1 10%' }}></Typography>
                                                {lesson.status == 2 && <Chip size={'small'} color={'warning'} variant={"outlined"} sx={{ p: 0, fontSize: '10px', height: '18px', lineHeight: '1' }} label={"postpone"} />}
                                                {lesson.status == 5 && <Chip size={'small'} color={'warning'} variant={"outlined"} sx={{ p: 0, fontSize: '10px', height: '18px', lineHeight: '1' }} label={"holiday"} />}
                                                {lesson.status == 3 && <Chip size={'small'} color={'warning'} variant={"outlined"} sx={{ p: 0, fontSize: '10px', height: '18px', lineHeight: '1' }} label={"canceled"} />}
                                            </Stack>
                                            {lesson.note && lesson.note.length > 0 && <Box sx={{bgcolor:"#fefefeaa",color:"#059a",fontSize:'12px'}}>{lesson.note}</Box>}
                                            </Box>                                            
                                            {(lesson.status == 1 || lesson.status == 9 ) && <Stack direction={"row"}>
                                                {(item<today || (item == today && isHomework)) && <Chip size={"small"} variant={"contained"} color={"primary"} sx={{p:0,fontSize:"10px",cursor:'pointer',height:'18px',lineHeight:"1",mr:'2px'}} label="Review lesson"  onClick={() => { handleClass(lesson) }} />}
                                                {item == today && isClass && <Chip size={"small"} variant={"contained"} color={"success"} sx={{p:0,fontSize:"10px",cursor:'pointer',height:'18px',lineHeight:"1",mr:'2px'}} onClick={() => { handleInClass(lesson) }} label="Enter lesson" />}
                                            </Stack>}
                                        </Paper>
                                    })}
                                </CardContent>
                            </Card>}</Grid>
                    })}
                </Grid>

            </Box>
            {hintMsg && <Snackbar anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }} open={hintMsg ? true : false} autoHideDuration={3000} onClose={handleHintClose}>
                <Alert onClose={handleHintClose} severity="success" sx={{ width: '100%' }}>{hintMsg}</Alert>
            </Snackbar>}
        </Box>
    )
}
export default Schedule