
import { Box, ListItem, ListItemText, Avatar, Paper, List, ListItemButton, Typography, ListSubheader, Stack, Button, IconButton, ListItemAvatar, Snackbar, Alert, Grid, CardHeader, Card, Chip, Badge } from "@mui/material"
import React, { useEffect, useState } from "react"
import apis from "../../api"

import { Weeks, Weeks1, apiResult, getLocaleDate, getTeacherSession, minuteToTime, orderList, } from "../../Utils/Common"
import { useNavigate, useParams } from "react-router"
import { AccessTime, ArrowLeft, ArrowRight, BrowserNotSupported, CheckBoxOutlineBlank, EventAvailable, EventBusy, Message, Person, PlaylistAddCheck, Toc } from "@mui/icons-material"
import { LOGO_ICON_COLOR, LOGO_TEXT_COLOR } from "../../app/config"


const CheckInBox = (props) => {
    const { checkin, onChange } = props
    const [status, setStatus] = useState(checkin)
    const handleStatus = (c) => {
        onChange(c, setStatus)
    }
    useEffect(() => {
        setStatus(checkin)
    }, [checkin])
    return status == 1 ? <EventAvailable sx={{ cursor: "pointer" }} onClick={() => handleStatus(0)} fontSize="small" />
        : <CheckBoxOutlineBlank sx={{ cursor: "pointer" }} onClick={() => handleStatus(1)} fontSize="small" />
}
const PageMemberItem = (props) => {
    const [status, setStatus] = useState(0)
    const [pass, setPass] = useState(0)
    const { memberName, pageStatus, passStatus, onClick, current } = props
    useEffect(() => {
        setStatus(pageStatus)
        setPass(passStatus)
    }, [pageStatus, passStatus])
    const handleClick = () => {
        if (status == 0) {
            //setStatus(1)
            onClick(1, setStatus)
        } else if (passStatus != 1) {
            //setStatus(0)
            onClick(0, setStatus)
        }
    }
    return <Avatar
        onClick={handleClick}
        sx={{
            border: "1px solid",
            width: 24, height: 24, fontSize: 12,
            bgcolor: status == 0 ? "#ccc" : (pass == 1 ? "green" : "orange"),
            display: current == 0 ? "none" : "fixed"
        }}
    >{memberName.substring(0, 2)}</Avatar>
}

const ClassHome = () => {
    const [class1, setClass1] = useState()
    const [error, setError] = useState()
    const [hint, setHint] = useState()
    const [currentMember, setCurrentMember] = useState(0)
    const [currentScheduleIdx, setCurrentScheduleIdx] = useState(0)
    const [selectedSchedule, setSelectedSchedule] = useState(0)
    const [tcheckin, setTcheckin] = useState(0)
    const session = getTeacherSession(apis)
    const navigate = useNavigate()
    if (!session) {
        navigate("/teacher/signin")
    }
    const params = useParams()
    let initPage = false
    useEffect(() => {
        if (!initPage) {
            initPage = true
            apis.teacherClass(params.id).then(ret => {
                apiResult(ret, data => {
                    data.class_schedules = orderList(data.class_schedules, 'sdate', 0)
                    data.course.lessons = orderList(data.course.lessons, 'lessonNo', 0)
                    for (let l in data.course.lessons) {
                        data.course.lessons[l].lesson_pages = orderList(data.course.lessons[l].lesson_pages, 'pageNo', 0)
                    }
                    setClass1(data)
                    let cs = getCurrentSchedule(data.class_schedules)
                    setCurrentScheduleIdx(cs)
                    setSelectedSchedule(cs)
                    if (data.class_schedules[cs].sdate == getLocaleDate() && data.class_schedules[cs].coach_lesson_record && data.class_schedules[cs].coach_lesson_record.status == 1) {
                        setTcheckin(1)
                    }
                }, setError)
            })
        }
    }, [])
    const countPages = (cls) => {
        let pages = 0
        if (cls && cls.course) {
            for (var l in cls.course.lessons) {
                pages += cls.course.lessons[l].lesson_pages.length
            }
        }
        return pages
    }
    const countMemberPage = (pages) => {
        let ret = { page: 0, pass: 0, points: 0 }
        for (var idx in pages) {
            let page = pages[idx]
            page.status == 1 && ret.page++
            page.pass == 1 && ret.pass++
            page.status == 1 && (ret.points += page.points)
        }
        return ret
    }
    const choiceMember = (memberid) => {
        if (currentMember == memberid) {
            setCurrentMember(0)
        } else {
            setCurrentMember(memberid)
        }
    }
    const pageMembers = (page, members) => {
        if (page.members) return page.members
        let mbs = []
        for (var idx in members) {
            var m = members[idx]
            let mm = { memberid: m.member_id, membername: m.member?.name }
            for (var mpidx in m.member.member_pages) {
                var mp = m.member.member_pages[mpidx]
                if (mp.page_id == page.id) {
                    mm.page = mp
                }
            }
            if (m.status == 1 || mm.page)
                mbs.push(mm)
        }
        page.members = mbs;
        return mbs;
    }
    const handlePageMember = (page, mp, status, callback) => {
        setError()
        setHint()
        if (!mp.page) {
            apis.teacherAssignPage({ classid: class1.id, courseid: class1.course_id, lessonid: page.lesson_id, pageid: page.id, memberid: mp.memberid }).then(ret => {
                apiResult(ret, data => {
                    mp.page = data
                    setHint("Success")
                    callback(status)
                }, setError)
            })
        } else {
            apis.teacherReAssignPage({ id: mp.page.id, status: status }).then(ret => {
                apiResult(ret, data => {
                    mp.page = data
                    setHint("Success")
                    callback(status)
                }, setError)
            })
        }
    }
    const handlePage = (cid, pid) => {
        window.open("/teacher/class/lesson/" + cid + "/" + pid)
    }
    const getCurrentSchedule = (schedules) => {
        let today = getLocaleDate()
        for (var idx in schedules) {
            let s = schedules[idx]
            if (s.sdate >= today) {
                return idx
            }
        }
        return schedules.length - 1
    }
    const handleSelectedSchedule = (idx) => {
        setSelectedSchedule(idx)


    }
    const isChecked = (memberid) => {
        let mlr = class1.class_schedules[selectedSchedule].member_lesson_records
        for (let idx in mlr) {
            if (mlr[idx].member_id == memberid && mlr[idx].checkin == 1) {
                return true
            }
        }
        return false
    }
    const handleCheckIn = (id) => {
        apis.teacherCheckin(id).then(ret => {    //??????
            apiResult(ret, data => {
                setTcheckin(1)
            }, setError)
        })
    }
    const handleMemberCheckin = (memberid, scheduleid, checkin, callback) => {
        if (!tcheckin) {
            setError("The teacher need to check in")
        }
        apis.teacherLessonRecordPost({ schedule_id: scheduleid, member_id: memberid, checkin }).then(ret => {
            apiResult(ret, data => {
                callback(checkin)
                checkin == 1 ? setHint("Signined in successfully") : setHint("Cancelled successfullu")
            }, setError)
        })
    }
    return <Box sx={{ p: 2 }}>
        <Paper sx={{ p: 2, m: 0.5 }}>
            <Stack direction={"row"}>
                <Stack direction={"column"} sx={{ minWidth: "10vw", border: "1px solid #eee", p: 1 }}>
                    <Typography sx={{ mb: 0.5, fontWeight: 'bold', fontSize: '24px', color: LOGO_ICON_COLOR }}>{class1?.name}</Typography>
                    <Typography sx={{ mb: 1, fontSize: '16px', color: LOGO_ICON_COLOR }}>Chapters:{countPages(class1)}</Typography>
                    {class1 && class1.class_schedules.length > 0 && <Box>
                        <Typography sx={{ fontSize: "16px" }}>{class1.class_schedules[selectedSchedule].sdate}</Typography>
                        {class1.class_schedules[selectedSchedule].sdate == getLocaleDate()
                            && (!tcheckin ? <Button variant="contained" color="success" onClick={() => handleCheckIn(class1.class_schedules[selectedSchedule].id)}>Check In</Button> : <Button variant="outlined" color="success" disabled>Signed</Button>)
                        }
                        {class1.class_schedules[selectedSchedule].sdate > getLocaleDate()
                            && <Button disabled variant="contained" color="success">Waiting</Button>
                        }
                        {class1.class_schedules[selectedSchedule].sdate < getLocaleDate()
                            && (!class1.class_schedules[selectedSchedule].coach_lesson_record || class1.class_schedules[selectedSchedule].coach_lesson_record.status == 0)
                            && <Button variant="contained" color="success" disabled>Absent</Button>
                        }
                        {class1.class_schedules[selectedSchedule].sdate < getLocaleDate()
                            && class1.class_schedules[selectedSchedule].coach_lesson_record && class1.class_schedules[selectedSchedule].coach_lesson_record.status == 1
                            && <Button variant="contained" color="success" disabled>Signed</Button>
                        }
                    </Box>}
                </Stack>
                <Box sx={{ pr: 2 }}>
                    <Stack direction={"row"}>
                        <IconButton onClick={() => { setCurrentScheduleIdx(currentScheduleIdx > 0 ? currentScheduleIdx - 1 : 0) }}><ArrowLeft /></IconButton>
                        {class1 && class1.class_schedules.map((schedule, idx) => {
                            if (idx >= currentScheduleIdx && idx < Number(currentScheduleIdx) + 5) {
                                let bgcolor = "inherit"
                                if (schedule.sdate == getLocaleDate()) {
                                    bgcolor = "success"
                                }
                                return <Stack direction={"column"} key={idx} sx={{ m: 1, p: 1, border: "1px solid #ddd", cursor: "pointer", bgcolor: selectedSchedule == idx ? "#eee" : "#fff" }}
                                    onClick={() => { handleSelectedSchedule(idx) }}
                                >
                                    <Button variant={bgcolor == "success" ? "contained" : "text"} sx={{ p: 0, m: 0.5 }} color={bgcolor} endIcon={
                                        schedule.sdate < getLocaleDate() ? (schedule.coach_lesson_record && schedule.coach_lesson_record.status == 1 ? <EventAvailable /> : <EventBusy />) :
                                            (schedule.sdate == getLocaleDate() && (tcheckin ? <EventAvailable /> : <CheckBoxOutlineBlank />))
                                    }>{schedule.sdate}</Button>
                                    <Typography sx={{ fontSize: "12px" }}>{Weeks[schedule.wod]} {minuteToTime(schedule.begintime)}-{minuteToTime(schedule.begintime + schedule.duration)}</Typography>
                                </Stack>
                            }
                        })}
                        <IconButton onClick={() => { setCurrentScheduleIdx(currentScheduleIdx < class1.class_schedules.length - 1 ? (Number(currentScheduleIdx) + 1) : (class1.class_schedules.length - 1)) }}><ArrowRight /></IconButton>
                    </Stack>
                    <Stack direction={"row"} sx={{ p: 1, overflow: "auto" }}>
                        {class1?.class_members?.map((m, idx) => {
                            let ms = countMemberPage(m.member.member_pages)
                            return <Card sx={{ mr: 1 }}>
                                <CardHeader
                                    // avatar={<Avatar sx={{ bgcolor: LOGO_ICON_COLOR }} aria-label="recipe">R</Avatar>}                                    
                                    title={<Stack direction={"row"}>
                                        {class1.class_schedules[selectedSchedule].sdate < getLocaleDate() && (isChecked(m.member.id) ? <EventAvailable style={{ color: "green" }} fontSize="small" /> : <BrowserNotSupported style={{ color: "red" }} fontSize="small" />)}
                                        {class1.class_schedules[selectedSchedule].sdate == getLocaleDate() && <CheckInBox memberid={m.member.id} scheduleid={class1.class_schedules[selectedSchedule].id} checkin={isChecked(m.member.id)} onChange={(c, callback) => handleMemberCheckin(m.member.id, class1.class_schedules[selectedSchedule].id, c, callback)} />}
                                        <Typography sx={{ fontSize: "16px", ml: 1, fontWeight: "bold" }}>{m.member.name}</Typography>
                                    </Stack>}
                                    subheader={<Stack direction={"row"}>
                                        <Button size="small" variant="outlined" sx={{ p: "0px !important", mr: 1 }} color={"success"} startIcon={<PlaylistAddCheck fontSize="small" />}>{ms.pass}</Button>
                                        <Button size="small" onClick={() => choiceMember(m.member.id)} variant={m.member.id == currentMember ? "contained" : "outlined"} sx={{ p: 0 }} color={"primary"} startIcon={<Toc fontSize="small" />}>{ms.page}</Button>
                                    </Stack>}
                                /></Card>
                        })}
                    </Stack>
                </Box>

            </Stack>
        </Paper>

        <Stack direction={"row"} sx={{ overflow: "auto", width: '100%', mt: 2 }}>
            {/* <Grid item xs={2}>
                <Paper sx={{ maxHeight: "800px", overflow: "scroll" }}>
                    <List>
                        {class1?.class_schedules?.map((schedule, idx) => {
                            return <ListItem dense divider disablePadding key={idx} sx={{}}>
                                <ListItemButton>
                                    <ListItemText primary={schedule.sdate} secondary={Weeks1[schedule.wod] + " " + String(minuteToTime(schedule.begintime)) + " - " + String(minuteToTime(schedule.begintime + schedule.duration))} />
                                </ListItemButton>
                            </ListItem>
                        })}
                    </List>
                </Paper>
            </Grid> */}
            {class1?.course?.lessons?.map((lesson, idx) => {
                return <Paper key={idx} sx={{ maxHeight: "800px", minWidth: "300px", maxWidth: "400px", overflow: "auto", m: 0.5 }}>
                    <List key={idx}
                        subheader={
                            <ListSubheader component="div" id="nested-list-subheader">{lesson.lessonNo} - {lesson.name}</ListSubheader>
                        }>
                        {lesson.lesson_pages.map((page, pidx) => {
                            let mps = pageMembers(page, class1.class_members)
                            page.tags = page.tags && (typeof page.tags == "string") ? page.tags.split(",") : page.tags
                            return <ListItem key={pidx} dense divider={pidx < lesson.lesson_pages.length - 1} disablePadding
                                secondaryAction={
                                    <IconButton edge="end" size="small" aria-label="page" onClick={() => { handlePage(class1.id, page.id) }}>
                                        {page.messages.length > 0 ? <Badge size="small" badgeContent={page.messages.length} color="error">
                                            <Message size="small" color="action" />
                                        </Badge> : <ArrowRight />}
                                    </IconButton>}>
                                <ListItemButton >
                                    <ListItemAvatar sx={{ minWidth: 32 }}>
                                        <Avatar sx={{ width: 24, height: 24, fontSize: 12, bgcolor: "#fff", color: "#888" }}>{page.pageNo}</Avatar>
                                    </ListItemAvatar>
                                    <ListItemText primary={page.title} secondary={
                                        <React.Fragment>
                                            <Stack direction={"row"}>
                                                {page.tags && page.tags.map((t, ii) => {
                                                    return <Chip size="small" variant="default" key={ii} label={"#" + t} />
                                                })}</Stack>
                                            {page.subtitle && <Typography variant="body2" sx={{ pt: 0.5, pb: 0.5 }}>{page.subtitle}</Typography>}
                                            <Stack direction={"row"}>
                                                {mps.map((mp, mpidx) => {
                                                    return <PageMemberItem key={mpidx} current={currentMember > 0 ? (mp.memberid == currentMember ? 1 : 0) : -1} memberName={mp.membername} pageStatus={mp.page && mp.page.status == 1 ? 1 : 0} passStatus={mp.page && mp.page.pass == 1 ? 1 : 0} onClick={(status, callback) => handlePageMember(page, mp, status, callback)} />
                                                })}
                                            </Stack>
                                        </React.Fragment>} />
                                </ListItemButton>
                            </ListItem>
                        })}
                    </List>
                </Paper>
            })}
        </Stack>
        <Snackbar
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
            open={error || hint ? true : false}
            onClose={() => { if (hint) { setError(); setHint() } }}
            autoHideDuration={3000}>
            <Alert onClose={() => { setError(); setHint() }} severity={error ? "error" : "success"} sx={{ width: '100%' }}>
                {error || hint}
            </Alert>
        </Snackbar>
    </Box>
}
export default ClassHome