import React from 'react'
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom'
import { createTheme, ThemeProvider } from '@mui/material/styles';

import './index.css'
import Layout from '../Component/Layout';
import SignUp from '../page/user/SignUp'
import {LoginRoute} from './AuthRoute'
import ResetPwd from '../page/user/ResetPwd'
import Terms from '../page/user/Terms'
import EmailVerify from '../page/user/EmailVerify';
import MemberSignin from '../page/member/Signin';
import MemberSchedule from '../page/member/Schedule';
import MemberLesson from '../page/member/Lesson';
import MemberInLesson from '../page/member/InLesson';

import TeacherSignin from '../page/teacher/Signin';
import TeacherSchedule from '../page/teacher/Schedule';
import TeacherLesson from '../page/teacher/Lesson';
import LessonShow from '../page/teacher/LessonShow';
import HomeSign from '../page/home/HomeSign';
import TeacherHome from '../page/teacher/Home';
import TeacherClassHome from '../page/teacher/ClassHome';
import MemberClassHome from '../page/member/ClassHome';
import TeacherPageHome from '../page/teacher/PageHome';
import MemberPageHome from '../page/member/PageHome';
import MemberHome from '../page/member/Home';
// import Event from '../page/event/Event';
const App = () => {
  const theme = createTheme();    
  return (
    <ThemeProvider theme={theme}>
      <Router>
        <Routes>          
          <Route path="/" element={<HomeSign />} />         
          <Route path="/user" element={<Layout />}>
            <Route path="signup" element={<SignUp />} />
            <Route path="signin" element={<LoginRoute/>} />
            <Route path="resetpwd" element={<ResetPwd />} />
            <Route path="terms" element={<Terms />} />
            <Route path="emailverify" element={<EmailVerify />} />
          </Route> 
          <Route path="/teacher/schedule" element={<TeacherSchedule />} />
          <Route path="/teacher/home" element={<TeacherHome />} />
          <Route path="/teacher/class/:id" element={<TeacherClassHome />} />
          <Route path="/teacher/signin" element={<TeacherSignin />} />
          <Route path="/teacher/class/lesson/:classid/:pageid" element={<TeacherPageHome />} />
          <Route path="/teacher/lesson/:scheduleid" element={<TeacherLesson />} />   
          <Route path="/teacher/lessonshow" element={<LessonShow />}/>
          <Route path="/member/signin" element={<MemberSignin/>} />
          <Route path="/member/home" element={<MemberHome />} />
          <Route path="/member/class/:id" element={<MemberClassHome />} />
          <Route path="/member/class/lesson/:classid/:pageid" element={<MemberPageHome />} />
          <Route path="/member/schedule" element={<MemberSchedule />} />
          <Route path="/member/lesson/:scheduleid" element={<MemberLesson />} /> 
          <Route path="/member/inlesson/:scheduleid" element={<MemberInLesson />} /> 
          {/* <Route path="/event/:code" element={<Event />} />            */}
        </Routes>
      </Router>
    </ThemeProvider>
  );
}

export default App;
