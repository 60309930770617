import { useEffect, useState } from "react"
import { Box, TextField, Stack, Alert,  } from "@mui/material"
import Uploader from "../../Component/Uploader";
import { getMemberSession, getTeacherSession, getUserSession } from "../../Utils/Common";
import apis from "../../api";
const UploaderItem = (props)=>{
    const {label,disabled,name,value,onChange,viewer,sx} = props
    const [_value,setValue] = useState(value)
    useEffect(()=>{setValue(value)},[value])
    const session = viewer === 'teacher'?getTeacherSession(apis):(viewer === 'member' ?getMemberSession(apis):getUserSession(apis))    
    return <Box sx={{ ...(sx?sx:{}),width: '100%' }}>
    {_value && _value.length > 0 ? <img src={_value?.substring(0, 4) === 'http' ? _value : ('/memberapi/resource/photo?file=' + _value)} style={{ maxWidth: '100%', height: '200px' }} />:<Alert severity="info">No submission</Alert>}
    {!disabled && <Stack direction="row">
        <TextField label={label} placeholder="Copy picture url to here or upload picture" onChange={(e) => { setValue(e.target.value);onChange && onChange(name, e.target.value) }} value={_value} fullWidth defaultValue={_value} />
        <Uploader session={session} viewer={viewer} apis={apis} onUpload={(f) => { setValue(f); onChange && onChange(name, f) }} />
    </Stack>}
</Box>
}
export default UploaderItem