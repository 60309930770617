import { Box, Container, Grid } from "@mui/material"
import { useEffect, useRef, useState } from "react"
import SlideContentItem from "../fragement/SlideContentItem"

const LessonShow = () => {
    const [contents, setContents] = useState()
    const [contentIdx, setContentIdx] = useState(0)
    const [maxIdx, setMaxIdx] = useState(0)
    const [index, setIndex] = useState(0)
    const [clicked, setClicked] = useState()
    const [covered, setCovered] = useState(false)
    const [itemContext,setItemContext] = useState()
    const [width,setWidth] = useState('100%')
    const listItemRef = []
    useEffect(() => {
        window.opener.afterShowWindowLoad()
    }, [])
    window.setContents = (data) => {
        setContents(data)
    }
    window.setItemContext = (data)=>{
        setItemContext([...data])        
    }
    window.handleChange = (contentidx, idx) => {
        if (contentIdx != contentidx) {
            setContentIdx(contentidx)
            setIndex(idx)
            setMaxIdx(idx)
        } else {
            setIndex(idx)
            idx > maxIdx && setMaxIdx(idx)
        }
        //window.scrollTo(0,listItemRef[idx]?.offsetTop)
    }
    window.handleTextClick = (subidx) => {
        subidx += 1
        if (clicked === subidx) {
            setClicked(-subidx)
        } else {
            setClicked(subidx)
        }
    }
    window.setContentCovered = () => {
        setCovered(!covered)
    }
    window.setWidth = (w)=>{
        setWidth(w)
    }
    return <Container maxWidth={width}>     
        {contentIdx >= 0 && <Grid container spacing={2} sx={{p:2}}>
            {contents && contents.length > 0 && contents[contentIdx].content && contents[contentIdx].content.map((item, idx) => {
                return maxIdx >= idx && item.showto != 1 && <Grid item ref={(el) => { listItemRef.push(el) }} key={idx} xs={item.grid ? Number(item.grid) : 12}>
                    <SlideContentItem itemContexts={itemContext} covered={covered} clicked={clicked} item={item} idx={idx} selected={index === idx} model={0} />
                </Grid>
            })}
            <Grid item xs={12} sx={{ height: '30vh' }}>
                <Box sx={{ height: '30vh' }}></Box>
            </Grid>
        </Grid>}
    </Container>
}
export default LessonShow