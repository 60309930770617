import { Alert, Box, Grid, ListItem, Stack, TextField, } from "@mui/material"
import React, { useEffect, useRef, useState } from "react"
import {useParams, useNavigate } from "react-router"
import apis from "../../api";
import { HiddenDrawer, RightDrawer } from "../../Component/MuiEx";
import { apiResult, getMemberSession, whatType } from "../../Utils/Common";

import LessonSideMenu from "../fragement/LessonSideMenu";
import SlideContentItem from "../fragement/SlideContentItem";

export default function Lesson() {    
    const params = useParams()
    const [contentIdx, setContentIdx] = useState(0)
    const [index, setIndex] = useState(0)
    const [error,setError] = useState()
    const [lesson, setLesson] = useState()
    const [contents, setContents] = useState()    
    const [schedule, setSchedule] = useState()    
    const [homeworks, setHomework] = useState()    
    const changes = useRef({})
    const [expand,setExpand] = useState(false)
    const navigate = useNavigate()
    if (!getMemberSession(apis)) {
        navigate('/member/signin')
    }

    const initPage = useRef(false)
    useEffect(() => {        
        if(initPage.current) {
           return 
        }
        initPage.current = true
        apis.memberLesson(params.scheduleid).then(ret => {
            apiResult(ret, data => {
                for (let i = 0; i < data.lesson.lesson_pages.length; i++) {
                    data.lesson.lesson_pages[i].content = data.lesson.lesson_pages[i].content.length > 0 ? JSON.parse(data.lesson.lesson_pages[i].content) : []
                }
                setContents(data.lesson.lesson_pages)
                setContentIdx(0)
                setLesson(data.lesson)
                setSchedule(data.schedule)
                setHomeworkData(data.homeworks)
                setIndex(0)                             
            },setError)
        })
        window.setInterval(updateHomework,5000)
        window.onunload = ()=>{
            if(changes.current.length>0) {
                updateHomework()
            }
        }        
    }, [])
    const setHomeworkData = (data)=>{
        for(let h of data) {
            h.content  = whatType(h.content) === 'string' && h.content.length>1?JSON.parse(h.content):undefined
        }
        setHomework(data)
    }
    const onPartItemClick = (item, idx) => {
        setContentIdx(idx)
        setIndex(0)        
    }
    const handleSectionClick=(idx,item)=>{
        setIndex(idx)
        // if(item.homework) {
            setExpand(true)
        // }
    }
    const getItemContextIndex = (id)=>{                
        if(homeworks) {
            for(let i=0;i<homeworks.length;i++) {
                let item = homeworks[i]
                if(item.item_id === id ) {
                    return i
                }
            }
        }
        return -1
    }
    const changed = useRef(false)
    const handleItemChange = (id,name,value)=>{
        let idx = getItemContextIndex(id)
        let hh = [...homeworks]
        let data = null
        if(idx<0) {
            data = {[name]:value}
            hh.push({item_id:id,content:data})
        }else {
            if(whatType(hh[idx].content) === 'object') {
                hh[idx].content[name] = value
            }else {
                hh[idx].content = {[name]:value}
            }            
            data = hh[idx].content
        }
        changes.current[id] = data        
        changed.current = true       
        
    }
    const updateHomework = ()=>{        
        if(Object.keys(changes.current).length>0) {
            changed.current = false
            apis.memberHomeworkPost({schedule_id:params.scheduleid,data:changes.current}).then(ret=>{
                apiResult(ret,data=>{                    
                    if(!changed.current) {
                        changes.current = {}
                    }
                },err=>setError("Fail to auto save the update"))
            })
        }
    }
    const handleExit = ()=>{        
        if(window.confirm("Are you sure to exit the lesson?")) {            
            window.close();
        }
    }
    return <Stack direction="row">
        {contents && lesson && <LessonSideMenu currentIdx={contentIdx} menus={contents} onExit={handleExit} onMenuItemClick={onPartItemClick} title={lesson.lessonNo + ":" + lesson.name} />}        
        <Box sx={{width:'100%'}}>
            {error && <Alert severity="error" onClose={()=>setError()}>{error}</Alert>}
            {contentIdx >= 0 && <Box sx={{width:'100%',maxHeight:'98vh',overflow:'auto'}}>
                <Grid container spacing={2} sx={{p:2}}>
                {contents && contents.length>0 && contents[contentIdx].content && contents[contentIdx].content.map((item, idx) => {                    
                    return item.showto != 1 &&<Grid key={idx} item xs={item.grid?Number(item.grid):12}>
                        <SlideContentItem viewer={'member'} itemContexts={homeworks} onChange={handleItemChange} item={item} idx={idx} sx={{width:'100%'}} selected={index === idx}  onClick={handleSectionClick}/>
                    </Grid> 
                })}                
                </Grid>
            </Box>}            
        </Box>
    </Stack>
//     return <Stack direction="row">
//     {contents && lesson && <LessonSideMenu currentIdx={contentIdx} menus={contents} onMenuItemClick={onPartItemClick} title={lesson.lessonNo + ":" + lesson.name} />}        
//     <Box sx={{width:'100%'}}>
//         {error && <Alert severity="error" onClose={()=>setError()}>{error}</Alert>}
//         {contentIdx >= 0 && <List sx={{width:'100%',maxHeight:'98vh',overflow:'auto' }}>
//             {contents && contents.length>0 && contents[contentIdx].content && contents[contentIdx].content.map((item, idx) => {                    
//                 return <ListItem key={idx} sx={{width:'100%'}}>
//                     <SlideContentItem viewer={'member'} itemContexts={homeworks} onChange={handleItemChange} item={item} idx={idx} sx={{width:'100%'}} selected={index === idx}  onClick={handleSectionClick}/>
//                 </ListItem> 
//             })}                
//         </List>}            
//     </Box>
//     <Box sx={{height:'99vh',display:expand?'block':'none',overflow:'auto'}}>
//         <TextField ></TextField>
//     </Box>
// </Stack>
}