import { ArrowRight, FaceOutlined, Message } from "@mui/icons-material"
import { Avatar,Stack,FormControl,InputLabel,Select,MenuItem, Box, Button, Card, CardActions, CardContent, CardHeader, Chip, Grid, Paper, Typography } from "@mui/material"
import { useEffect, useState } from "react"

import apis from "../../api"
import { LOGO_ICON_COLOR, LOGO_SLOGAN_COLOR, LOGO_TEXT_COLOR } from "../../app/config"
import { apiResult, getLocaleDate, getTeacherSession, minuteToTime, Weeks1 } from "../../Utils/Common"
import { useNavigate } from "react-router"
import { useRef } from "react"

const TeacherHome = () => {
    const [classes, setClasses] = useState()
    const [oclasses, setOClasses] = useState()
    const [term, setTerm] = useState()
    const [terms, setTerms] = useState([])
    // const [schedules,setSchedules] = useState()
    const [error, setError] = useState()
    getTeacherSession(apis)
    const loadDatas = () => {
        apis.teacherClasses().then(ret => {
            apiResult(ret, data => {
                let tt = []
                data.classes.map((c, idx) => {
                    if (tt.indexOf(c.term) < 0) {
                        tt.push(c.term)
                    }
                })
                data.oclasses.map((c, idx) => {
                    if (tt.indexOf(c.class.term) < 0) {
                        tt.push(c.class.term)
                    }
                })
                setTerms(tt)
                setClasses(data.classes)
                setOClasses(data.oclasses)
            }, setError)
        })
    }
    let initPage = false
    useEffect(() => {
        if (!initPage) {
            initPage = true
            // apis.teacherClasses().then(ret=>{
            //     apiResult(ret,data=>{
            //         setClasses(data)
            //     },setError)
            // })
            loadDatas()
        }
    }, [])

    const scheduleToShow = (schedules) => {
        let today = getLocaleDate()
        let ret = { isToday: false, wdate: "N", time: "No Schedule", status: 1 }
        if (schedules && schedules.length > 0) {
            let s = 0
            for (var ss in schedules) {
                if (s == 0 || s.sdate > schedules[ss].sdate) {
                    s = schedules[ss]
                }
            }
            ret.wdate = Weeks1[s.wod]
            if (s.sdate == today) {
                ret.isToday = true
                ret.time = String(minuteToTime(s.begintime)) + " - " + String(minuteToTime(s.begintime + s.duration))
            } else {
                ret.time = Weeks1[s.wod] + " " + String(minuteToTime(s.begintime)) + " - " + String(minuteToTime(s.begintime + s.duration))
            }
            ret.status = s.status
        }
        return ret
    }
    return <Box sx={{ p: 4 }}>
        <Stack direction="row" sx={{mb:2}}>
            <Typography variant="h4" sx={{ mb: 2,pr:6 }}>My Classes</Typography>
            <FormControl sx={{ width: 200 }}>
                <InputLabel id="select-term">{"Select Term"}</InputLabel>
                <Select labelId="select-term"
                    id="term" onChange={(e) => { setTerm(e.target.value); }}
                    label={"Select Term"}>
                    {terms && terms.map((p, idx) => {
                        return <MenuItem key={idx} value={p}>{p}</MenuItem>
                    })}
                </Select>
            </FormControl>
        </Stack>
        <Grid container spacing={2}>
            {classes && classes.map((cls, idx) => {
                if (!term || cls.term === term) {
                    var schedule = scheduleToShow(cls.class_schedules)
                    {/* console.log(cls.course.coursetype) */ }
                    return <Grid item xs={12} sm={3} key={idx}>
                        <Card sx={{ minWidth: 300, }}>
                            <CardHeader sx={{ bgcolor: "#ccc" }}
                                avatar={
                                    <Avatar sx={{ bgcolor: schedule.isToday ? LOGO_TEXT_COLOR : LOGO_ICON_COLOR }} aria-label="recipe">{schedule.wdate?.substring(0, 1)}</Avatar>
                                }
                                title={cls.name}
                                subheader={cls.course?.name}
                            />
                            <CardContent>
                                <Box sx={{ height: 100, overflow: "auto" }}>
                                    {cls.class_members.map((m, idx) => {
                                        return <Chip key={idx} variant="outlined" sx={{ margin: "3px" }} size="small" icon={<FaceOutlined />} label={m.member.name}></Chip>
                                    })}
                                </Box>
                                <Box sx={{ display: "flex", flexDirection: "row" }}>
                                    <Typography color="text.secondary" sx={{ flex: "1 1 30%" }} variant="body2">{cls.area ? cls.area.name : "York Mills"}</Typography>
                                    <Typography color="text.secondary" sx={{ flex: "1 1 40%" }} variant="body2">{schedule.time}</Typography>
                                    {schedule.status != 1 && <Typography color="text.secondary" sx={{ color: "red", flex: "1 1 30%" }} variant="body2">Postpone</Typography>}
                                </Box>
                            </CardContent>
                            <CardActions disableSpacing>
                                {cls.messages.length > 0 && <Button variant="contained" color="error" size="small" startIcon={<Message />}>{cls.messages.length}</Button>}
                                <Button sx={{ marginLeft: "auto" }} endIcon={<ArrowRight />} size="small" variant={schedule.isToday ? "contained" : "outlined"} onClick={() => { window.open(cls.course.coursetype == "free" ? ("/teacher/class/" + cls.id) : ("/teacher/schedule"), "class_detail") }}>Enter class</Button>
                            </CardActions>
                        </Card>
                    </Grid>
                }
            })}
        </Grid>
        {oclasses && oclasses.length > 0 && <Box>
            <Typography variant="h4" sx={{ mb: 2, mt: 2 }}>Substitute Classes</Typography>
            <Grid container spacing={2}>
                {oclasses && oclasses.map((cls, idx) => {
                    var schedule = scheduleToShow([cls])
                    return <Grid item xs={12} sm={3} key={idx}>
                        <Card sx={{ minWidth: 300, }}>
                            <CardHeader sx={{ bgcolor: "#ccc" }}
                                avatar={
                                    <Avatar sx={{ bgcolor: schedule.isToday ? LOGO_TEXT_COLOR : LOGO_ICON_COLOR }} aria-label="recipe">{schedule.wdate?.substring(0, 1)}</Avatar>
                                }
                                title={cls.class?.name}
                                subheader={cls.class?.course?.name}
                            />
                            <CardContent>
                                <Box sx={{ height: 100, overflow: "auto" }}>
                                    {cls.class?.class_members?.map((m, idx) => {
                                        return <Chip key={idx} variant="outlined" sx={{ margin: "3px" }} size="small" icon={<FaceOutlined />} label={m.member.name}></Chip>
                                    })}
                                </Box>
                                <Box sx={{ display: "flex", flexDirection: "row" }}>
                                    <Typography color="text.secondary" sx={{ flex: "1 1 30%" }} variant="body2">{cls.class?.area ? cls.class?.area?.name : "York Mills"}</Typography>
                                    <Typography color="text.secondary" sx={{ flex: "1 1 40%" }} variant="body2">{schedule.time}</Typography>
                                    <Typography color="text.secondary" sx={{ flex: "1 1 30%" }} variant="body2">{schedule.status}</Typography>
                                </Box>
                            </CardContent>
                            <CardActions disableSpacing>
                                <Button sx={{ marginLeft: "auto" }} size="small" variant={schedule.isToday ? "contained" : "outlined"} onClick={() => { window.open("/teacher/class/" + cls.class?.id, "class_detail") }}>Enter class</Button>
                            </CardActions>
                        </Card>
                    </Grid>
                })}
            </Grid>
        </Box>}
        {/* <List>
            {schedules && schedules.map((sch,idx)=>{
                return <ListItem key={idx}>
                    <Paper sx={{p:2}}>
                        <Typography variant="h6">{sch.class?sch.class.name:(sch.member?sch.member.name:"unkown")}</Typography>
                        <Typography variant="h6">{sch.teacher?sch.teacher.name:'No teacher'}</Typography>
                        <Typography variant="h6">{sch.assistant?sch.assistant.name:'No assistant'}</Typography>
                        <Typography variant="h6">{sch.sdate}</Typography>
                        <Typography variant="h6">{sch.begintime}</Typography>
                        <Typography variant="h6">{sch.duration}</Typography>
                        <Typography variant="h6">{sch.note}</Typography>
                    </Paper>
                </ListItem>
            })}
        </List> */}
    </Box>
}
export default TeacherHome