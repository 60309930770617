import { Alert, Box,  Grid, Paper, Snackbar, Stack,  } from "@mui/material"
import React, { useEffect, useRef, useState } from "react"

import { useParams, useNavigate } from "react-router"
import apis from "../../api";
import { apiResult, getMemberSession,  whatType, } from "../../Utils/Common";

import SlideContentItem from "../fragement/SlideContentItem";
import { NoData } from "../../Component/MuiEx";
import PageSide from "./fragement/PageSide";

export default function PageHome() {
    const [error, setError] = useState()
    const [page, setPage] = useState()    
    const [hintMsg, setHintMsg] = useState()
    const params = useParams()
    const navigate = useNavigate()
    const session = getMemberSession(apis)                
    const changed = useRef(false)
    const pageData = useRef(false)
    if (!session) {
        navigate('/member/signin')
    }
    const initPage = useRef(false)
    const { pageid, classid } = params
    useEffect(() => {
        if (initPage.current) {
            return
        }
        initPage.current = true
        apis.memberClassPage(classid, pageid).then(ret => {
            apiResult(ret, data => {
                if (data) {
                    data.content = data.content.length > 0 ? JSON.parse(data.content) : []
                    if(data.member_page) {
                        data.member_page.content = data.member_page.content != null && data.member_page.content.length>0?JSON.parse(data.member_page.content):[]
                        data.member_page.comment = data.member_page.comment != null && data.member_page.comment.length>0?JSON.parse(data.member_page.comment):[]
                    }                    
                    setPage(data) 
                    pageData.current = data                                  
                }
            }, setError)
        })
        window.setInterval(postUpdate, 5000)
        window.onunload = () => {
            postUpdate()
        }
    }, [])
    const getItemContextIndex = (id) => {
        if (pageData.current.member_page?.content) {
            for (let i = 0; i < pageData.current.member_page.content.length; i++) {
                let item = pageData.current.member_page.content[i]
                if (item.item_id === id) {
                    return i
                }
            }
        }
        return -1
    }
    const handleItemChange = (id, name, value) => {        
        changed.current = true
        let idx = getItemContextIndex(id)
        let data = null
        if (idx < 0) {
            data = { item_id: id, content: { [name]: value } }
            pageData.current.member_page.content.push(data)
        } else {
            if (whatType(pageData.current.member_page.content[idx].content) === 'object') {
                pageData.current.member_page.content[idx].content[name] = value
            } else {
                pageData.current.member_page.content[idx].content = { [name]: value }
            }
        }
    }
    const postUpdate = () => {        
        if (changed.current) {
            apis.memberPostPage({ id: pageData.current.member_page.id, content: JSON.stringify(pageData.current.member_page.content) }).then(ret => {
                apiResult(ret, data => {
                    setHintMsg("Save successfully")
                    changed.current = false                    
                }, setError)
            })
        }
    }    
    return <Stack direction="row">
        {!page || !page.member_page || page.member_page.status != 1 && <NoData />}        
        {page && page.member_page && page.member_page.status == 1 && <Paper sx={{ mr: 1, height: "100vh",width:"100%", overflow: "auto" }}>
            {<Grid container spacing={2} sx={{ p: 2 }}>
                {page.content && page.content.length > 0 && page.content.map((item, idx) => {                    
                    return <Grid item key={idx} xs={item.grid ? Number(item.grid) : 12}>
                        <SlideContentItem itemContexts={page.member_page.content} onChange={handleItemChange} viewer={'member'} sx={{ width: '100%' }} item={item} idx={idx} />
                    </Grid>
                })}                
            </Grid>
            }
        </Paper>}
        {page && <PageSide title={String(page.pageNo) + "-" + page.title} open={true} page={page} errorNotify={setError} />} 
        {(hintMsg || error) && <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'center' }} open={hintMsg || error ? true : false} autoHideDuration={3000} onClose={() => { setHintMsg(); setError() }}>
            <Alert onClose={() => { setHintMsg(); setError() }} severity={hintMsg ? "success" : "error"} sx={{ width: '100%' }}>{hintMsg || error}</Alert>
        </Snackbar>}
    </Stack>
}