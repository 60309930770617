
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import CssBaseline from '@mui/material/CssBaseline';
import { useNavigate } from 'react-router';
const HomeSign = () => {
    const navigate = useNavigate()
    return <Container component="main" maxWidth="xs" sx={{ marginTop: 10 }}>
        <CssBaseline />
        <Box sx={{ marginTop: 12, display: 'flex', flexDirection: 'column', alignItems: 'center', }}>
            <img src="/logo.png" style={{ height: "50px" }} />     
            <img src="/welcome.jpeg" style={{ height: "150px",marginTop:'80px',marginBottom:'40px' }} />          
            <Button fullWidth variant="contained" onClick={()=>{navigate('/member/signin')}} sx={{ mt: 3, mb: 2 }} >Student sign in </Button>     
            <Button fullWidth variant="outlined" sx={{ mb: 2 }}  onClick={()=>{navigate('/teacher/signin')}}>Teacher sign in </Button>     
        </Box></Container>
}
export default HomeSign