import { Box, Paper, TextField, Typography,Grid } from "@mui/material"
import { vs2015, CopyBlock, nord } from "react-code-blocks";
import { useEffect, useRef, useState } from "react";
import SimpleTable from "../../Component/SimpleTable";
import UploaderItem from "./UploaderItem";
import { MultiSelector, SingleSelector } from "../../Component/MuiEx";
import CodeEditor from "./CodeEditor";
import ItemContext from "./ItemContext";
const SlideContentItem = (props) => {
    const { item, idx,selected, model, onClick, onTextClick, clicked, covered, container, onChange, viewer, itemContexts } = props
    const [_itemContexts,setItemContexts] = useState(itemContexts)    
    const [items, setItems] = useState([])
    const paperRef = useRef()
    useEffect(() => {
        let ii = [...items]
        if (clicked > 0 && items.indexOf(clicked - 1) < 0) {
            ii.push(clicked - 1)
            setItems(ii)
        } else if (clicked < 0) {
            let i = items.indexOf(-clicked - 1)
            if (i >= 0) {
                ii.splice(i, 1)
                setItems(ii)
            }
        }
    }, [clicked])
    useEffect(()=>{           
        setItemContexts(itemContexts)        
    },[itemContexts])
    useEffect(() => {
        selected && paperRef && (container ? container : window).scrollTo(0, paperRef.current.parentElement?.offsetTop-40)
    })
    const handleClick = (subidx) => {
        let ii = [...items]
        if (ii.indexOf(subidx) >= 0) {
            ii.splice(ii.indexOf(subidx), 1)
        } else {
            ii.push(subidx)
        }
        setItems(ii)
        onTextClick && onTextClick(subidx)
    }

    const getItemContext = (id) => {
        let ret = undefined        
        if (_itemContexts) {
            for (let item of _itemContexts) {
                if (item.item_id === id) {                           
                    //return whatType(item.content) === 'object'?item.content:ret
                    return item.content
                }
            }
        }
        return ret
    }
    const handleChange = (id, name, value) => {
        onChange && onChange(id, name, value)
    }
    return <Paper ref={paperRef} sx={{ ...props.sx,width:'100%',height:'100%',position:'relative',m: model == 1 ? 2 : 0, border: selected && (model !== 1) ? '2px solid #059a' : '1px dotted gray', boxShadow: '0 0 0 0 !important', filter: selected && model !== 1 && "drop-shadow(5px 5px 5px gray)" }} onClick={() => { onClick && onClick(idx) }}>
        {item.type === 'content' ? <Grid container sx={{bgcolor:item.bgcolor,color:item.color,marginTop:item.mt?Number(item.mt):0,marginBottom:item.mb?Number(item.mb):0,p:item.p?Number(item.p):1}}>
            {item.content && item.content.map((subitem, sidx) => {
                let subidx = idx * 100 + sidx
                let choiced = items.indexOf(subidx) >= 0
                
                return (subitem.showto != 1 || viewer == 'teacher') && <Grid item xs={subitem.grid?Number(subitem.grid):12} >
                    <BlockShow key={sidx} item={subitem} choiced={choiced} itemContext={getItemContext(subitem.id)} handleClick={handleClick} subidx={subidx} handleChange={handleChange} viewer={viewer} />
                </Grid>
            })}
        </Grid>:<BlockShow item={item} itemContext={getItemContext(item.id) } handleClick={handleClick} handleChange={handleChange} idx={idx} viewer={viewer} selected={selected}/>}
        {!selected && covered && <Box sx={ { position:"absolute", top: 0, left: 0, bottom: 0, right: 0, bgcolor: '#fffd'}}></Box>}
    </Paper>
}

const BlockShow = (props) => {
    const subitem = props.item
    const { handleClick, handleChange, choiced, itemContext,subidx,selected,viewer,idx } = props   
    const [_itemContext,setItemContext] = useState(itemContext) 
    useEffect(()=>{setItemContext(itemContext)},[itemContext])
    let mt = subitem.mt?Number(subitem.mt):1
    let mb = subitem.mb?Number(subitem.mb):1    
    subitem.belongto = !subitem.belongto ? 'member':subitem.belongto
    //mt = selected ? mt + 2:mt
    //mb = selected ? mb + 2:mb
    return <Box sx={{height:subitem.height,mt:mt,mb:mb,p:subitem.p?Number(subitem.p):1,bgcolor:subitem.showto == 1?'#8884':subitem.bgcolor,color:subitem.showto==1?'#F98E54':subitem.color}}>
        {subitem.type === 'h' && <Typography onClick={() => subidx>=0 && handleClick(subidx)} variant={"h" + subitem.size} component={'h' + subitem.size} sx={{  color: subitem.color, fontWeight: (subitem.bold || choiced) ? 'bold' : '', textDecoration: choiced ? 'underline solid red 3px' : '' }}>{subitem.content}</Typography>}
        {subitem.type === 'text' && <Typography onClick={() => subidx >= 0 && handleClick(subidx)} variant={"body"} component={'p'} sx={{   color: subitem.color, fontSize: choiced ? '28px' : subitem.size, fontWeight: (subitem.bold || choiced) ? 'bold' : '', textDecoration: choiced ? 'underline solid red 3px' : '' }}>
            <div dangerouslySetInnerHTML={{ __html: subitem.content }}></div>
        </Typography>}
        {/* {subitem.type === 'html' && <div dangerouslySetInnerHTML={{ __html: subitem.content }}></div>} */}
        {subitem.type === 'code' && <Box sx={{  position: 'relative', fontSize: subitem.size ? subitem.size : '20px', }}>
            <CopyBlock text={subitem.content} theme={selected ? vs2015 : nord} language={'python'} showLineNumbers={false} wrapLines={true} ></CopyBlock>
        </Box>}
        {subitem.type === 'picture' && <img src={subitem.content && subitem.content.substring(0,4) === 'http'?subitem.content:'/memberapi/resource/photo?file='+subitem.content} style={{ width: subitem.width,maxHeight:subitem.height }} />}
        {subitem.type === 'video' &&  <iframe width={subitem.width} height={subitem.height} src={subitem.content} frameborder="0" allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>}
        {subitem.type === 'table' && <Box ><SimpleTable datas={subitem.content} color={subitem.color} size={subitem.size} /></Box>}
        {subitem.belongto === viewer || subitem.belongto === 'both' || !subitem.belongto ? <Box>
        {subitem.type === 'radio' && <SingleSelector  size={subitem.size} color={subitem.color} label={subitem.label} defaultValue={_itemContext?.member} name={subitem.id} items={subitem.content && subitem.content.length > 0 && subitem.content.split(',')} onChange={(name, value) => { handleChange(name, 'member', value) }} />}
        {subitem.type === 'option' && <MultiSelector  size={subitem.size} color={subitem.color} label={subitem.label} defaultValue={_itemContext?.member} name={subitem.id} items={subitem.content && subitem.content.length > 0 && subitem.content.split(',')} onChange={(name, value) => { handleChange(name, 'member', value) }} />}
        {subitem.type === 'input' && <TextField InputProps={{style:{fontSize:subitem.size}}} InputLabelProps={{ shrink: true }} multiline rows={subitem.height?subitem.height:3} name={subitem.id} onChange={(e) => { handleChange(subitem.id, "member", e.target.value) }} fullWidth label={subitem.label} defaultValue={_itemContext?.member} />}
        {subitem.type === 'codeinput' && <CodeEditor size={subitem.size}  label={subitem.label} value={_itemContext?.member} index={idx} subindex={0} height={subitem.height?subitem.height:"400px"} onChange={(v) => { handleChange(subitem.id, 'member', v) }} />}
        {subitem.type === 'upload' && <UploaderItem   viewer={viewer} name={subitem.id} label={subitem.label} value={_itemContext?.member} onChange={(name, value) => { handleChange(name, 'member', value) }} />}
        </Box>:<Box>
        {subitem.type === 'radio' && <SingleSelector size={subitem.size} color={subitem.color}  disabled={true} label={subitem.label} defaultValue={_itemContext?.member} name={subitem.id} items={subitem.content && subitem.content.length > 0 && subitem.content.split(',')}  />}
        {subitem.type === 'option' && <MultiSelector size={subitem.size} color={subitem.color} disabled={true} label={subitem.label} defaultValue={_itemContext?.member} name={subitem.id} items={subitem.content && subitem.content.length > 0 && subitem.content.split(',')} />}
        {subitem.type === 'input' && <TextField InputProps={{style:{fontSize:subitem.size}}} InputLabelProps={{ shrink: true }} disabled={true} multiline rows={6} name={subitem.id} fullWidth label={subitem.label} defaultValue={_itemContext?.member} />}
        {subitem.type === 'codeinput' && <CopyBlock size={subitem.size} text={itemContext?.member} theme={selected?vs2015:nord} language={'python'} showLineNumbers={false} wrapLines={true}/>}
        {subitem.type === 'upload' && <UploaderItem  disabled={true} viewer={viewer} name={subitem.id} label={subitem.label} value={_itemContext?.member}/>}
        </Box>}
        {['radio', 'option', 'input', 'codeinput', 'upload'].indexOf(subitem.type) >= 0 && (subitem.reply !== false) && (itemContext) &&  <ItemContext viewer={viewer} content={itemContext} id={subitem.id} onChange={(name, value) => handleChange(subitem.id, name, value)} />}
    </Box>
}

export default SlideContentItem